<template>
  <div id="a1">
  <el-dropdown @command="handleCommand">
  <span class="el-dropdown-link" id="a3">
    <img v-if="" id="head1" src="../assets/h1.png"   alt="">
    <div id="a5">超级管理员</div>
    王大锤<i class="el-icon-arrow-down el-icon--right" id="a4"></i>
  </span>
    <el-dropdown-menu  slot="dropdown">
      <el-dropdown-item command="王大锤1">王大锤1</el-dropdown-item>
      <el-dropdown-item command="王大锤2">王大锤2</el-dropdown-item>
      <el-dropdown-item command="王大锤3">王大锤3</el-dropdown-item>
      <el-dropdown-item disabled command="王大锤4">王大锤4</el-dropdown-item>
      <el-dropdown-item divided command="王大锤5">王大锤5</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  </div>
</template>

<script>
export default {
  methods: {
    handleCommand(command) {
      this.$message('欢迎管理员' + command);
    }
  }
}
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #FFFFFF;
}
.el-icon-arrow-down {
  font-size: 28px;
}
#a1{
  position: absolute;
  width: 159px;
  height: 42px;
  left: 1685px;
  top: 29px;

}
#head1{
  position: absolute;
  width: 42px;
  height: 42px;
  left: -57px;
  top: 0;
}
#a3{
  position: absolute;
  width: 48px;
  height: 20px;
  left: 57px;
  top: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}
#a4{
  position: absolute;
  width: 28px;
  height: 28px;
  left: 50px;
  top: 10px;
}
#a5{
  position: absolute;
  width: 60px;
  height: 15px;
  left: -5px;
  top: 26px;

  font-family: 'Microsoft YaHei UI',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #777777;
}

</style>
