import Vue from 'vue'
import Router from 'vue-router'
import Personnel from "@/views/Personnel";
import operationLog from "@/views/operationLog";
import register from "@/views/register";
import Device from "@/views/Device";


Vue.config.errorHandler = (err, vm, info) => {
  // 处理网络错误逻辑，例如显示错误提示等
  console.error(err)
}


const EndpointMonitoring = () => import("../views/EndpointMonitoring")


const stockControl = () =>import("../views/stockControl")



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "",
      redirect:"/register"
    },
    {
      path: "/register",
      name: "showAll",
      component: register
    },
    {
      path: "/EndpointMonitoring",
      name: "showAll",
      component: EndpointMonitoring
    },
    {
      path: "/stockControl",
      name: "cupStore",
      component:stockControl
    },
    {
      path: "/Personnel",
      name: "cupStore",
      component:Personnel
    },
    {
      path: "/Device",
      name: "cupStore",
      component:Device
    },
    {
      path: "/operationLog",
      name: "cupStore",
      component:operationLog
    },
  ]
})
