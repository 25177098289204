<template>
  <div id="resbac1">
    <nobr>
    <div>
    <img id="resp1" src="../assets/a1.png"  alt="">
    <div id="resr1">{{ res.name }}</div>
    <div id="resr2">{{ res.id }}</div>
    <div id="resr3" v-if="res.post===1">仓库管理</div>
    <div id="resr3" v-if="res.post===2">维护工</div>
    <div id="resr3" v-if="res.post===3">设备管理</div>
    <div id="resr3" v-if="res.post===4">工程师</div>
    <div id="resr3" v-if="res.post===5">员工管理</div>
    <div id="resp2"> <el-switch
      v-model="value"
      @change="change"
      active-color="#3A61F6"
      inactive-color="#B7B7B7"></el-switch></div>
    <div id="resb1"></div>
    </div>
    </nobr>
  </div>
</template>

<script>
export default {
  props:['res','recoverParams'],
  name: "repair",
  data(){
    return{
      checked:true,
      value:false,
    }
  },
  methods:{
    change(){
      if(this.value===true){
          this.recoverParams.push(this.res.id)
      }
        if (this.value===false){
          for (let i=0;i<this.recoverParams.length;i++){
            if (this.recoverParams[i]===this.res.id){
              this.recoverParams.splice(i,1)
            }
          }
        }
    }
  }
}
</script>

<style scoped>
#resbac1{
  width: 314px;
  height: 82px;
  left: 0;
  top: 0;
}
#resp1{
  position: relative;
  width: 42px;
  height: 42px;
  left: 0;
  top: 0;
}
#resr1{
  position: relative;
  width: 42px;
  height: 18px;
  left: 52px;
  top: -45px;

  font-family: 'Microsoft YaHei UI',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}
#resr2{
  position:relative;
  width: 57px;
  height: 15px;
  left: 50px;
  top: -40px;

  font-family: 'Microsoft YaHei UI',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #3C3C3C;
}
#resr3{
  text-align: center;

  position: relative;
  width: 200px;
  height: 18px;
  left: 90px;
  top: -68px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}
#resb1{
  position: relative;
  width: 314px;
  height: 1px;
  top: -70px;
  left:0 ;

  background-color:  #E8E8E8;
}
#resp2{
  position: relative;
  width: 50px;
  height: 26px;
  left: 270px;
  top:-90px;
}
</style>
