<template>
  <div id="personnelBg">
    <button id="btn1" @click="addAccessPerson" class="btn1">
      <img src="../assets/b5.png" style="position: relative;
width: 20px;
height: 20px;
" alt="">权限管理
    </button>

    <button id="btn2" @click="addPerson=!addPerson" class="btn2">
      <img src="../assets/b4.png" style="position: relative;
width: 20px;
height: 20px;
" alt="">新增员工
    </button>

    <button id="btn4" @click="restorePersonBtn" class="btn4">
      <img src="../assets/b6.png" style="position: relative;
width: 20px;
height: 20px;
" alt="">恢复员工
    </button>

    <input type="text" placeholder="输入员工姓名" id="Ip1" @blur="searchPersonnel" @keyup.enter="searchPersonnel"
           v-model="searchPersonnelName">
    <img src="../assets/search.png" id="p1" alt="">
    <div id="a1"></div>


    <table id="tab1" style="height: 50px">
      <thead>
      <tr>
        <th style="width: 240px;
height: 50px;">工号
        </th>
        <th style="width: 240px;
height: 50px;">姓名
        </th>
        <th style="width: 240px;
height: 50px;">手机
        </th>
        <th style="width: 240px;
height: 50px;">邮箱
        </th>
        <th style="width: 240px;
height: 50px;">职务
        </th>
        <th style="width: 164px;
height: 50px;">操作
        </th>
        <th style="
width: 160px;
height: 50px;">操作
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(kind,index) in data.records" class="personcolor">
        <td v-model="kind.id">{{ kind.id }}</td>
        <td v-model="kind.name">{{ kind.name }}</td>
        <td v-model="kind.phone">{{ kind.phone }}</td>
        <td v-model="kind.email">{{ kind.email }}</td>
        <td v-if="kind.post===1">仓库管理</td>
        <td v-if="kind.post===2">维护工</td>
        <td v-if="kind.post===3">设备管理</td>
        <td v-if="kind.post===4">工程师</td>
        <td v-if="kind.post===5">员工管理</td>
        <td v-if="kind.post===6">超级管理员</td>
        <td>
          <img src="../assets/edit.png" id="p2" alt="">
          <button id="btn5" @click="editPersonMSg(index)" class="personlBtn1">
            编辑
          </button>
        </td>
        <td>
          <img src="../assets/b7.png" id="p3">
          <button id="btn5" @click="personnelDisable(index)" class="personlBtn1">
            禁用
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!--禁用员工弹出框-->
    <div id="disableBackground" v-if="personnelForbid">
      <div id="disableText">禁用员工</div>
      <div id="disableText1">确定要禁用此员工吗？</div>
      <button id="disableBtn" @click="personnelForbid=!personnelForbid">取消</button>
      <button id="disableBtn1" class="disableBtn1" @click="personnelDisableAffirm">确定</button>
    </div>


    <div v-if="addPerson" id="addbac1">
      <div id="adda1">新增员工</div>
      <img src="../assets/X.png" id="addp1" @click="addPerson=!addPerson" alt="">
      <div id="adda2">姓名</div>
      <div id="adda3">职务</div>
      <div id="adda4">手机</div>
      <div id="adda5">邮箱</div>
      <input type="text" placeholder="" id="addinp1" class="addinp1" v-model="addPersonName">
      <div id="addinp2">
        <el-select v-model="addPersonJob">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <input type="text" placeholder="" id="addinp3" class="addinp3" v-model="addPersonPhone">
      <input type="text" placeholder="" id="addinp4" class="addinp4" v-model="addPersonEmail">
      <div id="addbtn1" @click="addPerson=!addPerson">取消</div>
      <div id="addbtn2" @click="addPersonHand">提交</div>
    </div>


    <div v-if="editPerson" id="addbac2">
      <div id="eda1">编辑员工</div>
      <img src="../assets/X.png" id="addp2" @click="editPerson=!editPerson" alt="">
      <div id="eda2">工号</div>
      <div id="eda3">姓名</div>
      <div id="eda4">职务</div>
      <div id="eda5">手机</div>
      <div id="eda6">邮箱</div>
      <div id="eda7">是否启用</div>
      <input type="text" id="edinp1" class="edinp1" v-model="editData.id" disabled>
      <button id="edBtn1" @click="restorePassword=!restorePassword;editPerson=!editPerson">重置密码</button>
      <input type="text" id="edinp2" class="edinp2" v-model="editData.name">
      <div id="edinp3">
        <el-select v-model="editPersonValue">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <input type="text" id="edinp4" class="edinp4" v-model="editData.phone">
      <input type="text" id="edinp5" class="edinp5" v-model="editData.email">
      <div id="eda8" @click="editPerson=!editPerson">取消</div>
      <div id="eda9" @click="editPersonHand">提交</div>
      <div id="eda10">
        <el-switch
            style='zoom:1.2;'
            v-model="value"
            active-color="#3A61F6"
            inactive-color="#B7B7B7"
        >
        </el-switch>
      </div>
    </div>


    <!--点击重置密码后显示的页面-->
    <div id="disableBackground" v-if="restorePassword">
      <div id="disableText">重置密码</div>
      <div id="disableText1">{{ editData.name }}({{editData.id}})的密码将被重置为默认密码</div>
      <button id="disableBtn" @click="restorePassword=!restorePassword;editPerson=!editPerson">取消</button>
      <button id="disableBtn1" class="disableBtn1" @click="restorePasswordAffirm">确定</button>
    </div>



    <div v-if="restorePerson" id="addbac3">
      <div id="rebac2"></div>
      <div id="rea1">恢复员工</div>
      <img src="../assets/X.png" id="rep1" @click="restorePersonCancel" alt="">
      <input type="text" placeholder="输入姓名" id="reinp1" v-model="restorePersonName" @blur="searchRestorePerson"
             @keyup.enter="searchRestorePerson">
      <img src="../assets/smallSearch.png" id="rep2" alt="">
      <div id="rebac1" style="overflow-x:hidden;overflow-y:auto">
        <table>
          <tr v-for="(kind,index) in restorePersonData" style="background: #FFFFFF;">
            <restorePerson :res="kind" :recoverParams="recoverParams"></restorePerson>
          </tr>
        </table>
      </div>
      <button id="restorePersonCancel" @click="restorePersonCancel">取消</button>
      <button id="restorePersonHand" class="restorePersonHand" @click="restorePersonHand">提交</button>
    </div>


    <div v-if="accessPerson" id="addbac4">
      <div id="ace1">权限管理</div>
      <img src="../assets/X.png" id="acep1" @click="accessPerson=!accessPerson" alt="">
      <div id="aceip1">
        <el-select v-model="accessPersonValue" @change="change">
          <el-option
              v-for="item in options"

              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <button id="acebtn1" class="acebtn1" @click="searchDefaultPermissions">
        <div id="aceb1">默认值</div>
        <img src="../assets/a2.png" id="acep2" alt="">
      </button>
      <div id="acebtn2" @click="accessPerson=!accessPerson">取消</div>
      <button id="acebtn3" @click="accessPersonHand">提交</button>
      <div id="ace2">职务</div>
      <div id="ace3">
        <div id="acc1">监控总表</div>
        <div id="acc2">状态显示</div>
        <div id="acc3">查询分析</div>
        <div id="acc4">修改阈值</div>
        <div id="accb1">
          <el-switch
              v-model="value1"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
        <div id="accb2">
          <el-switch
              v-model="value2"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
        <div id="accb3">
          <el-switch
              v-model="value3"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
      </div>
      <div id="ace4">
        <div id="acc5">单个终端</div>
        <div id="acc6">状态显示</div>
        <div id="acc7">查询分析</div>
        <div id="accb1">
          <el-switch
              v-model="value4"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
        <div id="accb2">
          <el-switch
              v-model="value5"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
      </div>
      <div id="ace5">
        <div id="acc8">终端单点</div>
        <div id="acc9">状态显示</div>
        <div id="acc10">查询分析</div>
        <div id="acc11">更换记录</div>
        <div id="accb1">
          <el-switch
              v-model="value6"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
        <div id="accb2">
          <el-switch
              v-model="value7"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
        <div id="accb3">
          <el-switch
              v-model="value8"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
      </div>
      <div id="ace6">
        <div id="acc12">人员管理</div>
        <div id="accb1">
          <el-switch
              v-model="value9"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
      </div>
      <div id="ace7">
        <div id="acc13">库存管理</div>
        <div id="accb1">
          <el-switch
              v-model="value10"
              active-color="#3A61F6"
              inactive-color="#B7B7B7">
          </el-switch>
        </div>
      </div>
    </div>


    <!--人员管理页码-->
    <div>
      <div id="pg1">共</div>
      <div id="pg2" v-model="pagetotal=data.pages">{{ pagetotal }}</div>
      <div id="pg3">页</div>
      <div id="pg4" @click="pageup(pagenumber)">上一页</div>
      <div id="pg5" v-model="pagenumber">{{ data.current }}</div>
      <div id="pg6" @click="pagedown(pagenumber)">下一页</div>
      <div id="pg7">前往</div>
      <input type="text" class="Page" v-model="pageMsg" maxlength="4" id="ip1" @blur="toPageNumber()"
             @keyup.enter="toPageNumber()"
             onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9] /g,'')}else{this.value=this.value.replace(/\D/g,'')}">
      <div id="pg8">页</div>
    </div>


    <tit></tit>
  </div>
</template>

<script>
import restorePerson from "@/components/restorePerson";
import tit from "@/components/tit";
import headmenu from "@/components/headmenu";
import axios from "@/components/api.js";


export default {
  components: {restorePerson, tit, headmenu},
  name: "Personnel",
  data() {
    return {
      restorePassword:false,
      addPerson: false,
      editPerson: false,
      value: false,
      restorePerson: false,
      accessPerson: false,
      personnelForbid: false,
      value1: false,
      value2: false,
      value3: false,
      value4: false,
      value5: false,
      value6: false,
      value7: false,
      value8: false,
      value9: false,
      value10: false,
      pagenumber: 1,
      pagetotal: '',
      pageMsg: '',
      data: [],
      editData: [],
      deleteId: [],
      options: [{
        value: '1',
        label: '仓库管理'
      }, {
        value: '2',
        label: '维护工'
      }, {
        value: '3',
        label: '设备管理'
      }, {
        value: '4',
        label: '工程师'
      }, {
        value: '5',
        label: '员工管理'
      }],
      accessPersonValue: '',
      editPersonValue: '',
      addPersonName: '',
      addPersonJob: '',
      addPersonPhone: '',
      addPersonEmail: '',
      searchPersonnelName: null,
      restorePersonName: null,
      codeList: [],
      restorePersonData: [],
      recoverParams: [],
    }
  },
  watch: {
    name() {
      // 人员管理监听方法
      this.personnelRenew(this.pagenumber)
    }
  },
  created() {
    this.personnelRenew(1)
    this.$store.commit("headIndexChange", 3)
  },
  methods: {
    //重置密码确定按钮方法
    restorePasswordAffirm(){
      axios.put(this.$store.state.localhost+'employee/password',{
        id: this.editData.id
      },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          //成功弹窗
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },

    //恢复员工弹窗中的取消方法
    restorePersonCancel() {
      this.restorePerson = !this.restorePerson
      this.recoverParams = []
    },

    //恢复员工弹窗中的提交方法
    restorePersonHand() {
      axios.put(this.$store.state.localhost+'employee/recover',
          this.recoverParams
          ,
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelRenew(this.pagenumber)
          this.restorePerson = !this.restorePerson
          this.recoverParams = []
          //成功弹窗
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }

      })
    },


    //恢复员工搜索方法
    searchRestorePerson() {
      this.restorePersonAxios()
    },

    //点击恢复员工发的请求
    restorePersonAxios() {
      axios.get(this.$store.state.localhost+'employee/locked', {
        params: {
          name: this.restorePersonName,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.restorePersonData = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },

    //点击恢复员工按钮进行的操作
    restorePersonBtn() {
      this.restorePersonAxios()
      this.restorePerson = !this.restorePerson

    },


    //权限管理弹窗中的提交按钮
    accessPersonHand() {
      if (this.value1 === true) {
        this.codeList.push('total:status')
      }
      if (this.value2 === true) {
        this.codeList.push('total:charts')
      }
      if (this.value3 === true) {
        this.codeList.push('total:edit')
      }
      if (this.value4 === true) {
        this.codeList.push('point:status')
      }
      if (this.value5 === true) {
        this.codeList.push('point:charts')
      }
      if (this.value6 === true) {
        this.codeList.push('cup:status')
      }
      if (this.value7 === true) {
        this.codeList.push('cup:charts')
      }
      if (this.value8 === true) {
        this.codeList.push('cup:log')
      }
      if (this.value9 === true) {
        this.codeList.push('emp:admin')
      }
      if (this.value10 === true) {
        this.codeList.push('stock:admin')
      }
      axios.post(this.$store.state.localhost+'employee/updatecode', {
            codeList: this.codeList,
            postName: this.accessPersonValue,
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.accessPerson = !this.accessPerson
          this.codeList = []
          //成功弹窗
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //选择不同的职位触发的方法
    change() {
      this.value1 = false;
      this.value2 = false;
      this.value3 = false;
      this.value4 = false;
      this.value5 = false;
      this.value6 = false;
      this.value7 = false;
      this.value8 = false;
      this.value9 = false;
      this.value10 = false;
      this.searchAcccess(this.accessPersonValue)
    },


    //默认按钮点击触发方法显示不同状态
    searchDefaultPermissions() {
      this.value1 = false;
      this.value2 = false;
      this.value3 = false;
      this.value4 = false;
      this.value5 = false;
      this.value6 = false;
      this.value7 = false;
      this.value8 = false;
      this.value9 = false;
      this.value10 = false;
      axios.get(this.$store.state.localhost+'employee/reset', {
        params: {
          postName: this.accessPersonValue,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.defaultPermissions(res.data.data);
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //查询默认权限和单选框绑定
    defaultPermissions(defaultData) {
      for (let i = 0; i < defaultData.length; i++) {
        if (defaultData[i] === "total:status") {
          this.value1 = true;
        }
        if (defaultData[i] === "total:charts") {
          this.value2 = true;
        }
        if (defaultData[i] === "total:edit") {
          this.value3 = true;
        }
        if (defaultData[i] === "point:status") {
          this.value4 = true;
        }
        if (defaultData[i] === "point:charts") {
          this.value5 = true;
        }
        if (defaultData[i] === "cup:status") {
          this.value6 = true;
        }
        if (defaultData[i] === "cup:charts") {
          this.value7 = true;
        }
        if (defaultData[i] === "cup:log") {
          this.value8 = true;
        }
        if (defaultData[i] === "emp:admin") {
          this.value9 = true;
        }
        if (defaultData[i] === "stock:admin") {
          this.value10 = true;
        }
      }
    },


    //查询权限的请求方法
    searchAcccess(searchValue) {
      axios.get(this.$store.state.localhost+'employee/code', {
        params: {
          postName: searchValue,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.defaultPermissions(res.data.data);

        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //点击权限管理触发的方法
    addAccessPerson() {
      this.accessPerson = !this.accessPerson
    },


    //搜索框搜索方法
    searchPersonnel() {
      this.personnelRenew(1);
    },


    //新增员工弹窗中的提交按钮方法
    addPersonHand() {
      axios.post(this.$store.state.localhost+'employee', {
            post: this.addPersonJob,
            name: this.addPersonName,
            phone: this.addPersonPhone,
            email: this.addPersonEmail,
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelRenew(this.pagenumber)
          this.addPerson = !this.addPerson
          //成功弹窗
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //人员管理中的禁用按钮方法
    personnelDisable(index) {
      this.personnelForbid = !this.personnelForbid
      this.deleteId[0] = this.data.records[index].id
    },

    //禁用按钮弹窗中确认按钮方法
    personnelDisableAffirm() {
      axios.put(this.$store.state.localhost+'employee/delete',
          this.deleteId
          ,
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelRenew(this.pagenumber)
          this.personnelForbid = !this.personnelForbid
          //成功弹窗
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //编辑页面提交按钮方法
    editPersonHand() {
      let valueState = 1;
      if (this.value === true) {
        valueState = 1
      }
      if (this.value === false) {
        valueState = 0
      }
      if (this.editPersonValue === "仓库管理") {
        this.editPersonValue = 1
      }
      if (this.editPersonValue === "维护工") {
        this.editPersonValue = 2
      }
      if (this.editPersonValue === "设备管理") {
        this.editPersonValue = 3
      }
      if (this.editPersonValue === "工程师") {
        this.editPersonValue = 4
      }
      if (this.editPersonValue === "员工管理") {
        this.editPersonValue = 5
      }
      if (this.editPersonValue === "超级管理员") {
        this.editPersonValue = 6
      }
      if (this.editData.state === 1) {
        this.value = true
      }
      if (this.editData.state === 0) {
        this.value = false
      }
      axios.put(this.$store.state.localhost +'employee', {
            id: this.editData.id,
            post: this.editPersonValue,
            name: this.editData.name,
            phone: this.editData.phone,
            email: this.editData.email,
            state: valueState,
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelRenew(this.pagenumber)
          this.editPerson = !this.editPerson
          //成功弹窗
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })

    },


    //点击编辑按钮提交的方法
    editPersonMSg(index) {
      axios.get(this.$store.state.localhost+'employee', {
        params: {
          id: this.data.records[index].id,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.editData = res.data.data
          if (this.editData.post === 1) {
            this.editPersonValue = "仓库管理"
          }
          if (this.editData.post === 2) {
            this.editPersonValue = "维护工"
          }
          if (this.editData.post === 3) {
            this.editPersonValue = "设备管理"
          }
          if (this.editData.post === 4) {
            this.editPersonValue = "工程师"
          }
          if (this.editData.post === 5) {
            this.editPersonValue = "员工管理"
          }
          if (this.editData.post === 6) {
            this.editPersonValue = "超级管理员"
          }
          if (this.editData.state === 1) {
            this.value = true
          }
          if (this.editData.state === 0) {
            this.value = false
          }
          this.editPerson = !this.editPerson
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },

    //人员管理数据更新
    personnelRenew(pagenumber) {
      axios.get(this.$store.state.localhost+'employee/page', {
        params: {
          page: pagenumber,
          pageSize: 14,
          name: this.searchPersonnelName,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code===1) {
          this.data = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //上一页跳转请求
    pageup(pagenumber) {
      if (pagenumber === 1) {
        return;
      }
      this.pagenumber--;
      this.personnelRenew(this.pagenumber);
    },
    //下一页跳转请求
    pagedown(pagenumber) {
      if (pagenumber === parseInt(this.data.pages)) {
        return;
      }
      this.pagenumber++;
      this.personnelRenew(this.pagenumber);
    },
    //跳转到输入的页面
    toPageNumber() {
      if (this.pageMsg <= this.data.pages && this.pageMsg.length !== 0) {
        this.personnelRenew(this.pageMsg)
      } else {
        this.$message({
          message: '页码不存在',
          type: 'error',
          offset: 300
        });
      }
    },
  }
}
</script>

<style scoped>
#personnelBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 2000px;
  background: black;
}

#btn1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 140px;
  height: 36px;
  left: 197px;
  top: 145px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
  border-radius: 4px;
}

.btn1 {
  background: #3A61F6;
}

.btn1:hover {
  background: #4cc9f0;
}


#btn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;

  width: 140px;
  height: 36px;
  left: 347px;
  top: 145px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
  border-radius: 4px;
}

.btn2 {
  background: #3A61F6;
}

.btn2:hover {
  background-color: #4cc9f0;
}


#btn3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 140px;
  height: 36px;
  left: 497px;
  top: 145px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;

  background: #3A61F6;
  border-radius: 4px;
}

#btn4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 140px;
  height: 36px;
  left: 497px;
  top: 145px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
  border-radius: 4px;
}

.btn4 {
  background: #3A61F6;
}

.btn4:hover {
  background-color: #4cc9f0;
}


#Ip1 {
  box-sizing: border-box;
  position: absolute;

  width: 400px;
  height: 38px;
  left: 1321px;
  top: 143px;
  background: #242424;

  border: 1px solid #404040;
  border-radius: 4px;

  padding-left: 20px;
  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;

  outline: none;
}

#p1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 1688px;
  top: 148px;
}

#a1 {
  position: absolute;
  width: 1525px;
  height: 0;
  left: 197px;
  top: 200px;

  border: 1px solid #555555;
}

#tab1 {
  position: absolute;
  width: 1524px;
  height: 750px;
  left: 197px;
  top: 240px;
}


#btn5 {
  margin-left: -20px;
  padding-left: 10px;
  justify-content: center;

  width: 140px;
  height: 34px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;

  border: 1px solid #444444;
  border-radius: 4px;
}

#p2 {
  position: relative;
  width: 20px;
  height: 20px;
  left: 40px;
  top: 4px;
}

#p3 {
  position: relative;
  width: 20px;
  height: 20px;
  left: 35px;
  top: 4px;
}

#addbac1 {
  position: absolute;
  width: 374px;
  height: 425px;
  left: 773px;
  top: 327px;

  background: #F3F3F5;
  border-radius: 12px;
}

#adda1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 20px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#addp1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 20px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#adda2 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 85px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#adda3 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 195px;
  top: 85px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;

}

#adda4 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 165px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#adda5 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 251px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#addinp1 {
  box-sizing: border-box;

  position: absolute;
  width: 149px;
  height: 40px;
  left: 30px;
  top: 109px;
  padding-left: 12px;
}

.addinp1 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.addinp1:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#addinp2 {
  position: absolute;
  width: 149px;
  height: 46px;
  left: 195px;
  top: 109px;
}


#addinp3 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 189px;
  padding-left: 12px;
}


.addinp3 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.addinp3:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#addinp4 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 275px;
  padding-left: 12px;
}


.addinp4 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.addinp4:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#addbac2 {
  position: absolute;
  width: 374px;
  height: 567px;
  left: 773px;
  top: 257px;

  background: #F3F3F5;
  border-radius: 12px;
}

#addbtn1 {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.1em;

  color: rgba(38, 41, 45, 0.6);

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 362px;

  background: #E9E9E9;
  border-radius: 4px;
}

#addbtn2 {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.1em;

  color: #FFFFFF;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 362px;

  background: #3A61F6;
  border-radius: 4px;
}

#addp2 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 19px;
}

#eda1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 19px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#eda2 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 84px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#eda3 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 170px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#eda4 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 195px;
  top: 170px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#eda5 {
  position: absolute;
  width: 140px;
  height: 18px;
  left: 30px;
  top: 250px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#eda6 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 334px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#eda7 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 30px;
  top: 436px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#edinp1 {
  box-sizing: border-box;

  position: absolute;
  width: 198px;
  height: 46px;
  left: 30px;
  top: 108px;

  padding-left: 12px;
}

#edBtn1{
  position: absolute;
  width: 100px;
  height: 38px;
  left: 240px;
  top: 112px;

  background: #3A61F6;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;
}


.edinp1 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.edinp1:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#edinp2 {
  box-sizing: border-box;

  position: absolute;
  width: 149px;
  height: 40px;
  left: 30px;
  top: 194px;

  padding-left: 12px;
}

.edinp2 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.edinp2:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#edinp3 {
  position: absolute;
  width: 149px;
  height: 46px;
  left: 195px;
  top: 194px;
}

#edinp4 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 274px;

  padding-left: 12px;
}

.edinp4 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.edinp4:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#edinp5 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 360px;

  padding-left: 12px;
}

.edinp5 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.edinp5:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#eda8 {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 503px;

  background: #E9E9E9;
  border-radius: 4px;
}

#eda9 {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 503px;

  background: #3A61F6;
  border-radius: 4px;
  color: #FFFFFF;
}

#eda10 {
  position: absolute;
  width: 50px;
  height: 26px;
  left: 294px;
  top: 432px;
}

#addbac3 {
  position: absolute;
  width: 374px;
  height: 637px;
  left: 773px;
  top: 221px;

  background: #FFFFFF;
  border-radius: 12px;
}

#rea1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 20px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#rep1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 20px;
}

#rep2 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 274px;
  top: 24px;
}

#reinp1 {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
  gap: 49px;

  position: absolute;
  width: 149px;
  height: 30px;
  left: 157px;
  top: 19px;

  border: 1px solid #CBCBCB;
  border-radius: 6px;
  outline: none;
}

#ace1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 20px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#ace2 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 85px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#ace3 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 102px;
  left: 30px;
  top: 171px;

  background: #FFFFFF;
  border-radius: 6px;
}

#ace4 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 70px;
  left: 30px;
  top: 281px;

  background: #FFFFFF;
  border-radius: 6px;
}

#ace5 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 102px;
  left: 30px;
  top: 359px;

  background: #FFFFFF;
  border-radius: 6px;
}

#ace6 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 38px;
  left: 30px;
  top: 469px;

  background: #FFFFFF;
  border-radius: 6px;
}

#ace7 {
  box-sizing: border-box;

  position: absolute;
  width: 314px;
  height: 38px;
  left: 30px;
  top: 515px;

  background: #FFFFFF;
  border-radius: 6px;
}

#acc1 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 12px;
  top: 42px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc2 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 10px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc3 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 42px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc4 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 74px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc5 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 12px;
  top: 26px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc6 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 10px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc7 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 42px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc8 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 12px;
  top: 42px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc9 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 10px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc10 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 42px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc11 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 84px;
  top: 74px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc12 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 12px;
  top: 10px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#acc13 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 12px;
  top: 10px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #212121;
}

#addbac4 {
  position: absolute;
  width: 374px;
  height: 651px;
  left: 773px;
  top: 214px;

  background: #F3F3F5;
  border-radius: 12px;
}

#acep1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 20px;
}

#aceip1 {
  position: absolute;
  width: 149px;
  height: 46px;
  left: 30px;
  top: 109px;
}

#acebtn1 {
  box-sizing: border-box;

  position: absolute;
  width: 149px;
  height: 39px;
  left: 195px;
  top: 109px;

  border-radius: 6px;
  border-style: none;
}

.acebtn1 {
  background: #3A61F6;
}

.acebtn1:hover {
  background-color: #4cc9f0;
}

#aceb1 {
  position: absolute;
  width: 42px;
  height: 18px;
  left: 60px;
  top: 10px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;
}

#acep2 {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 40px;
  top: 10px;
}

#accb1 {
  position: absolute;
  width: 40px;
  height: 20px;
  left: 262px;
  top: 9px;
}

#accb2 {
  position: absolute;
  width: 40px;
  height: 20px;
  left: 262px;
  top: 41px;
}

#accb3 {
  position: absolute;
  width: 40px;
  height: 20px;
  left: 262px;
  top: 73px;
}

#acebtn2 {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 588px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.1em;

  color: rgba(38, 41, 45, 0.6);

  background: #E9E9E9;
  border-radius: 4px;
}

#acebtn3 {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 588px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.1em;

  color: #FFFFFF;

  background: #3A61F6;
  border-radius: 4px;
  border-style: none;
}

#rebac1 {
  position: absolute;
  width: 340px;
  height: 472px;
  left: 30px;
  top: 85px;
}

#rebac2 {
  position: absolute;
  width: 374px;
  height: 1px;
  left: 0;
  top: 65px;

  background-color: #E9E8E8;
}

#pg1 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 758px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg2 {
  position: absolute;
  width: 24px;
  height: 25px;
  left: 796px;
  top: 1060px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #777777;
}

#pg3 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 835px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg4 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 901px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg5 {
  position: absolute;
  width: 12px;
  height: 25px;
  left: 976px;
  top: 1060px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #FFFFFF;
}

#pg6 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 1015px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg7 {
  position: absolute;
  width: 32px;
  height: 20px;
  left: 1113px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg8 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 1200px;
  top: 1062px;


  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

.Page {
  position: absolute;
  width: 60px;
  height: 16px;
  left: 1150px;
  top: 1062px;

  color: #4cc9f0;
  background: black;
  border-style: none;
}

#ip1 {
  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  outline: none;
}

.personcolor {
  color: #FFFFFF;
}

.personcolor:hover {
  color: #5E97FF;
}

.personlBtn1 {
  background: #242424;
}

.personlBtn1:hover {
  background: #3A61F6;
}

/*禁用弹窗样式*/
#disableBackground {
  position: absolute;
  width: 374px;
  height: 178px;
  left: 773px;
  top: 451px;

  background: #F3F3F5;
  border-radius: 12px;
}


#disableText {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#disableText1 {
  position: absolute;
  width:500px;
  height: 18px;
  left: 30px;
  top: 55px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;


  color: #3C3C3C;
}

#disableBtn {
  align-content: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 115px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;


  background: #E9E9E9;
  color: rgba(38, 41, 45, 0.6);
  border-radius: 4px;
  border-style: none;
}

#disableBtn1 {
  align-content: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 115px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;


  border-radius: 4px;

  color: #FFFFFF;
  border-style: none;
}

.disableBtn1 {
  background: #3A61F6;
}

.disableBtn1:hover {
  background-color: #4cc9f0;
}


/*恢复员工中取消和提交的css样式*/
#restorePersonCancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 574px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  background: #E9E9E9;
  color: rgba(38, 41, 45, 0.6);
  border-radius: 4px;
  border-style: none;
}

#restorePersonHand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 574px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;
  border-radius: 4px;
  border-style: none;
}

.restorePersonHand {
  background: #3A61F6;
}

.restorePersonHand:hover {
  background-color: #4cc9f0;
}


/*表格通用样式*/
tr:nth-child(odd) {
  background-color: #000000;
}

tr:nth-child(even) {
  background-color: #101010;
}

th {
  background: #222222;
  height: 50px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #444444;
  height: 50px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
</style>
