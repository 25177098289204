import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    localhost:'https://api.yiyenanjing.com/',
    repairmanId: '',
    repairmanName: '',
    //刷新总表分页
    refreshData: true,
    //规则设置中表示是否为一组数据
    msgDecideIndex:false,
    //登录失败内容
    loginFalseMsg: '',
    //标题存储index位置
    currentIndex: 1,
    //库存管理数据刷新设置
    refreshStoreData:true,
    //规则设置index位置
    setIndex: "",
  //  存储输入的密码
    password:false,

  },
  getters: {},
  mutations: {





    /**
     * 刷新总表分页
     * @param state
     */
    refreshChange(state) {
      state.refreshData = !state.refreshData;
    },




    // 登录错误时显示内容
    addloginMsg(state, data) {
      state.loginFalseMsg = data;
    },
    /**
     * 标题模块选择index存储
     * @param state
     * @param index
     */
    headIndexChange(state, index) {
      state.currentIndex = index;
    },




    /**
     * 库存管理数据刷新
     * @param state
     */
    refreshStoreDataChange(state){
      state.refreshStoreData=!state.refreshStoreData;
    },

  },
  actions: {},
  modules: {}
})
