<template>
  <div>
    <div id="personnelSetInputBg1" :class="{ 'highlighted': msg[index].highlighted }" v-if="personnelSetInpStatus"
         @click="highlightArea(index)">
      <div :class="{ 'highlightedIndex': msg[index].highlighted ,'highlightedIndex2': !msg[index].highlighted} "
           id="personnelSetIndex">层{{ index + 1 }}
      </div>
      <input type="text" id="personnelSetInputMsg1" v-if="!msg[index].personnelSetImgStatus" disabled
             v-model="msg[index].layerName" @click="highlightArea(index)">
      <input ref="input" type="text" id="personnelSetInputMsg1" v-if="msg[index].personnelSetImgStatus"
             v-model="msg[index].layerName" @click="highlightArea(index)" autocomplete=“off”>
      <div id="personnelSetImgBg1" class="personnelSetImgBg1" @click="personnelSetInpDelete(index)" v-if="$store.state.msgDecideIndex">
        <img src="../assets/delete_normal.png" alt=""
             style="position: absolute;width: 18px;height: 18px;left: 3px;top: 3px">
      </div>
      <div id="personnelSetImgBg2" class="personnelSetImgBg2" @click="personnelSetImgStatusDecide(index)"
           v-if="!msg[index].personnelSetImgStatus&&$store.state.msgDecideIndex">
        <img src="../assets/edit_normal.png" alt=""
             style="position: absolute;width: 18px;height: 18px;left: 3px;top: 3px">
      </div>
      <div id="personnelSetImgBg3" class="personnelSetImgBg3"
           v-if="msg[index].personnelSetImgStatus&&$store.state.msgDecideIndex"
           @click="msg[index].personnelSetImgStatus=!msg[index].personnelSetImgStatus">
        <img src="../assets/commit.png" alt=""
             style="position: absolute;width: 18px;height: 18px;left: 3px;top: 3px">
      </div>
    </div>
    <div id="personnelSetInputBg2" @click="personnelSetInputAddData()" v-if="index===msg.length-1&&msg.length<=9&&$store.state.msgDecideIndex">
      <img src="../assets/Minus_XL.png" alt=""
           style="position: relative;left: 140px;top:12px;width: 24px;height: 24px">
    </div>
  </div>
</template>

<script>
export default {
  name: "textSetting",
  props: ['msg', 'index'],
  data() {
    return {
      msgDecideIndex: false,
      highlighted: false,
      personnelSetinputMsg: "",
      personnelSetInpStatus: true,

    }
  },

  created() {

  this.msgIndex()

  },

  watch: {

  },

  methods: {
    //判断初始数组是否为一个
    msgIndex(){
      if (this.msg.length===1){
        this.$store.state.msgDecideIndex=true
      }
    },


    //点击加号增加输入信息
    personnelSetInputAddData() {
      if (this.msg[this.msg.length - 1].layerName !== "" && this.msg.length < 10)
        this.msg.push({
          "layerName": "",
          "layerContent": [],
          "highlighted": false,
          "personnelSetImgStatus": false
        })

      for (let i = 0; i < this.msg.length; i++) {
        this.msg[i].highlighted = false;
        this.msg[i].personnelSetImgStatus = false;
      }
      this.msg[this.msg.length - 1].highlighted = true
      this.msg[this.msg.length - 1].personnelSetImgStatus = !this.msg[this.msg.length - 1].personnelSetImgStatus;
    },


    //点击删除按钮
    personnelSetInpDelete(index) {

      if (index<this.msg.length-1){
        this.msg.splice(index, 1)
      }
      // if (index===this.msg.length-1){
      //   this.msg.pop()
      // }

    },

    //点击区域显示蓝色边缘
    highlightArea(index) {
      for (let i = 0; i < this.msg.length; i++) {
        this.msg[i].highlighted = false;
      }
      this.msg[index].highlighted = true;
    },

    //规则设置中点击编辑按钮聚焦输入框的方法
    personnelSetImgStatusDecide(index) {
      for (let i = 0; i < this.msg.length; i++) {
        this.msg[i].personnelSetImgStatus = false;
      }
      this.msg[index].personnelSetImgStatus = !this.msg[index].personnelSetImgStatus;
      if (this.msg[index].personnelSetImgStatus) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
  }
}
</script>

<style scoped>
#personnelSetInputBg2 {
  position: relative;
  width: 320px;
  height: 48px;
  margin-top: 8px;

  border-radius: 4px;
  background: #ECECF0;
}

#personnelSetInputBg1 {
  z-index: 10;

  position: relative;
  width: 318px;
  height: 48px;
  margin-top: 8px;

  border-radius: 4px;
  background: #ECECF0;
}

.highlighted {
  border: 1px solid #3A61F6;
}


#personnelSetInputMsg1 {
  position: absolute;
  left: 82px;
  top: 14.5px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  border: none;

  background: #ECECF0;
  outline: none;
}


#personnelSetImgBg2 {
  position: absolute;
  left: 250px;
  top: 12px;
  width: 24px;
  height: 24px;

  border-radius: 4px;
}

.personnelSetImgBg2 {
  background: #949FB3;
}

.personnelSetImgBg2:hover {
  background: #3A61F6;
}

#personnelSetImgBg1 {
  position: absolute;
  left: 282px;
  top: 12px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.personnelSetImgBg1 {
  background: #949FB3;
}

.personnelSetImgBg1:hover {
  background: #FF5345;
}

#personnelSetImgBg3 {
  position: absolute;
  left: 282px;
  top: 12px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.personnelSetImgBg3 {
  background: #3A61F6;
}

.personnelSetImgBg3:hover {
  background: #31C0FF;
}

input:-webkit-autofill {
  box-shadow: #ECECF0 inset !important;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #333 !important;
  transition: background-color 5000s ease-in-out 0s !important;
}


.highlightedIndex {
  background: #3A61F6;
}

.highlightedIndex2 {
  background: #949FB3;
}

#personnelSetIndex {
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  width: 62px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px 0 0 4px;

}

</style>