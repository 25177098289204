<template>
  <div id="app">

    <router-view/>

  </div>
</template>
<script>
import register from "@/views/register";

export default {
  components:{register},
  name: 'App',
}
</script>
<style>
#app{
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  background-color: #131313;
}
button{
  border: none;
  outline: none;
}
</style>
