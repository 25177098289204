<template>
  <div id="registerBg1">
  <div id="registerBg">
    <div id="registerText1">请登录</div>
    <div id="registerText2">手机号:</div>
    <div id="registerText3">密码:</div>
    <button id="registerBtn1" @click="messageClean">清空</button>
    <router-link to="/" tag="button" id="registerBtn2" @click.native="login" class="registerBtn2">登录</router-link>
    <input type="text" id="registerInput1" class="registerInput1" v-model="phone1">
    <input :type='passwordChangeInput?"text":"password"' id="registerInput2" class="registerInput2" v-model="password1">

    <img src="../assets/register1.png" alt="" id="registerImg" v-if="password" @click="passwordChange">
    <img src="../assets/register2.png" alt="" id="registerImg" v-if="!password" @click="passwordChange">
  </div>
  </div>
</template>

<script>
import axios from "axios";


export default {
  components: {axios},
  name: "register",

  data() {
    return {
      phone1: '',
      password1: '',
      password: true,
      passwordChangeInput: false,
    }
  },
  methods: {
    //密码显示和隐藏方法
    passwordChange() {
      this.password = !this.password
      this.passwordChangeInput = !this.passwordChangeInput
    },
    //清除已经输入的登录内容
    messageClean() {
      this.password1 = ''
      this.phone1 = ''
    },
    //登录按钮提交方法
    login() {
      if (this.phone1 !== '' && this.password1 !== '') {
        axios.post(this.$store.state.localhost+'employee/login', {
              // this.对变量而言很有必要
              phone: this.phone1,
              password: this.password1,
            }
        ).then((res) => {
          //账号密码正确时返回的内容
          if (res.data.code === 1) {
            // res.data是必要的，后面的是数据的路径
            localStorage.setItem('token', res.data.data.token);
            this.$router.push('/EndpointMonitoring')
            if (this.password1==='123456'){
              this.$store.state.password=true
            }
          }
          //账号密码错误时返回的内容
          if (res.data.code===0) {
            this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
                }
            )
          }
          if (res.data.code===401) {
            this.$message({
                  showClose: true,
                  message: '请正确输入手机号和密码',
                  type: 'error'
                }
            )
          }
        })

      }
    },
  }
}
</script>

<style scoped>
#registerBg1 {
  position: relative;
  width: 1920px;
  height: 1080px;

  background: #E0E0E0;
}
#registerBg {
  box-sizing: border-box;

  position: absolute;
  width: 380px;
  height: 464px;
  left: 770px;
  top: 308px;

  background: #FFFFFF;
  border-radius: 12px;
}

#registerText1 {
  position: absolute;
  width: 150px;
  height: 46px;
  left: 33px;
  top: 60px;

  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;


  color: #000000;
}

#registerText2 {
  position: absolute;
  width: 64px;
  height: 20px;
  left: 33px;
  top: 146px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #8D8D8D;
}

#registerText3 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 33px;
  top: 236px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #8D8D8D;
}

#registerBtn1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;
  text-align: center;

  position: absolute;
  width: 155px;
  height: 38px;
  left: 33px;
  top: 396px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  background: #E9E9E9;
  color: rgba(38, 41, 45, 0.6);
  border-radius: 4px;
  border-style: hidden;
}

#registerBtn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;
  text-align: center;

  position: absolute;
  width: 155px;
  height: 38px;
  left: 192px;
  top: 396px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;
  border-radius: 4px;
  border-style: hidden;
}

.registerBtn2{
  background: #3A61F6;
}
.registerBtn2:focus{
  background: #4cc9f0;
}

#registerInput1 {
  padding-bottom: 5px;

  position: absolute;
  width: 314px;
  height: 20px;
  left: 33px;
  top: 180px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

#registerInput2 {
  padding-bottom: 5px;

  position: absolute;
  width: 314px;
  height: 20px;
  left: 33px;
  top: 270px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
}

.registerInput1 {
  border-bottom: 1px solid #8D8D8D;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
}

.registerInput1:focus {
  border-bottom: 1px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.registerInput2 {
  border-bottom: 1px solid #8D8D8D;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
}

.registerInput2:focus {
  border-bottom: 1px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

#registerImg {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 323px;
  top: 266px;
}
</style>
