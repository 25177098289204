<template>
  <div id="deviceBg">

    <input type="text" id="createId" v-model="createId" placeholder="id" disabled>
    <button id="createIdBtn" @click="createIdBtn" class="createIdBtn">
      生成1个
    </button>
    <button id="createIdBtn1"  class="createIdBtn1" @click="createIdBtn1">
      生成100个
    </button>
    <!--标题栏下划线设置-->
    <div id="bacDevice"></div>
    <!--设备信息表格-->
    <table id="tab1" style="height: 50px">
      <thead>
      <tr>
        <th style="width: 182px;
height: 50px;">设备编号
        </th>
        <th style="width: 340px;
height: 50px;">设备名称
        </th>
        <th style="width: 101px;
height: 50px;">位置
        </th>
        <th style="width: 179px;
height: 50px;">型号
        </th>
        <th style="width: 179px;
height: 50px;">油脂种类
        </th>
        <th style="width: 179px;
height: 50px;">容量
        </th>
        <th style="
width: 100px;
height: 50px;">档位
        </th>
        <th style="
width: 100px;
height: 50px;">阈值
        </th>
        <th style="
width: 164px;
height: 50px;">操作
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(kind,index) in data.records" class="personcolor">
        <td v-model="kind.terminalId">{{ kind.terminalId }}</td>
        <td v-model="kind.terminalName">{{ kind.terminalName }}</td>
        <td v-model="kind.no">{{ kind.no }}</td>
        <td v-model="kind.cupType">{{ kind.cupType }}</td>
        <td v-model="kind.oilType">{{ kind.oilType }}</td>
        <td v-model="kind.volume">{{ kind.volume }}</td>
        <td v-model="kind.shift">{{ kind.shift }}</td>
        <td v-model="kind.threshold">{{ kind.threshold }}</td>
        <td>
          <img src="../assets/edit.png" id="p2" alt="">
          <button id="btnDevice" @click="editDeviceMSg(index)" class="btnDevice">
            编辑
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!--点击编辑按钮显示的页面-->
    <div v-if="editDevice" id="editDevicebac1">
      <div id="editDevicea1">编辑设备信息</div>
      <img src="../assets/X.png" id="editDevicep1" @click="editDevice=!editDevice" alt="">
      <div id="editDevicea2">设备名称</div>
      <div id="editDevicea3">位置</div>
      <div id="editDevicea4">型号</div>
      <div id="editDevicea5">油脂种类</div>
      <div id="editDevicea6">档位</div>
      <div id="editDevicea7">报警阈值</div>
      <input type="text" id="editDeviceip1" v-model="selectData.terminalName" disabled>
      <input type="text" id="editDeviceip2" v-model="selectData.no" disabled>
      <div id="editDeviceip3">
        <el-select v-model="editDeviceValue1">
          <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="editDeviceip4">
        <el-select v-model="editDeviceValue2">
          <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div id="editDeviceip5">
        <el-select v-model="editDeviceValue3">
          <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <input type="text" id="editDeviceip6" class="editDeviceip6" v-model="selectData.threshold">
      <div id="editDevicebtn1" @click="editDevice=!editDevice">取消</div>
      <div id="editDevicebtn2" @click="editDeviceHand">提交</div>
    </div>


    <!--维护记录页码-->
    <div id="returnBackPage">
      <div id="changgeLogPg1">共</div>
      <div id="changgeLogPg2">{{ data.pages }}</div>
      <div id="changgeLogPg3">页</div>
      <div id="changgeLogPg4" @click="deviceChangePageup(pagenumber)">上一页</div>
      <div id="changgeLogPg5" v-model="pagenumber">{{ pagenumber }}</div>
      <div id="changgeLogPg6" @click="deviceChangePagedown(pagenumber)">下一页</div>
      <div id="changgeLogPg7">前往</div>
      <input type="text" class="changgeLogPage" maxlength="4" v-model="pageMsg" id="changgeLogip1"
             @blur="deviceChangePagenumber"
             @keyup.enter="deviceChangePagenumber"
             onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9] /g,'')}else{this.value=this.value.replace(/\D/g,'')}">
      <div id="changgeLogPg8">页</div>
    </div>


    <!--搜索框-->
    <input type="text" id="searchDeviceId" v-model="terminalName" placeholder="输入设备名称"
           @blur="deviceRefresh" @keyup.enter="deviceRefresh">
    <img src="../assets/search.png" alt="" id="searchDeviceImg1">


    <tit z-index="100"></tit>
  </div>
</template>

<script>
import tit from "@/components/tit";
import axios from "@/components/api.js";

export default {
  components: {tit},
  name: "Device",

  data() {
    return {
      textData:'',
      createId: null,
      terminalName: null,
      pagenumber: 1,
      pageMsg: '',
      data: [],
      editDevice: false,
      selectData: [],
      editDeviceIndex: '',
      editDeviceValue1: '',
      editDeviceValue2: '',
      editDeviceValue3: '',
      options1: [{
        value: 'ABC000125',
        label: 'ABC000125'
      }, {
        value: 'DEF000180',
        label: 'DEF000180'
      }, {
        value: 'XYZ000260',
        label: 'XYZ000260'
      }, {
        value: 'OKK000150',
        label: 'OKK000150'
      }, {
        value: '3QQ000320',
        label: '3QQ000320'
      }],
      options2: [{
        value: 'GT630',
        label: 'GT630'
      }, {
        value: 'GTX1060',
        label: 'GTX1060'
      }, {
        value: 'MX450',
        label: 'MX450'
      }, {
        value: 'RX6600',
        label: 'RX6600'
      }, {
        value: 'RTX2060',
        label: 'RTX2060 '
      }],
      options3: [{
        value: 1,
        label: '1'
      }, {
        value: 2,
        label: '2'
      }, {
        value: 3,
        label: '3'
      }, {
        value: 4,
        label: '4'
      }, {
        value: 5,
        label: '5'
      }, {
        value: 6,
        label: '6'
      }, {
        value: 7,
        label: '7'
      }, {
        value: 8,
        label: '8'
      }, {
        value: 9,
        label: '9'
      }, {
        value: 10,
        label: '10'
      }, {
        value: 11,
        label: '11'
      }, {
        value: 12,
        label: '12'
      }],


    }

  },
  created() {
    this.deviceRefresh();
    this.$store.commit("headIndexChange", 4)
  },

  methods: {
    //生成文本随机数100个
    createIdBtn1(){
      // 生成随机数数据
      let randomData = [];
      for (let i = 0; i < 100; i++) {
        axios.get(this.$store.state.localhost + 'common/generateid', {
               params: {

               },
               headers: {
                 token: localStorage.getItem('token')
               }
             }).then((res) => {
               if (res.data.code === 1) {
                 randomData.push(res.data.data);
                 // 数组数据全部获取完毕后执行以下代码
                 if (randomData.length === 100) {
                   // 将数组数据转换为字符串格式
                   let textData = randomData.join('\n');

                   // 创建一个Blob对象，将文本作为数据传入
                   let blob = new Blob([textData], { type: 'text/plain' });

                   // 创建一个File对象
                   let file = new File([blob], 'random_data.txt');

                   // 创建一个下载链接
                   let downloadLink = document.createElement('a');
                   downloadLink.href = URL.createObjectURL(file);
                   downloadLink.download = file.name;

                   // 将下载链接添加到页面中，并模拟点击以触发下载
                   document.body.appendChild(downloadLink);
                   downloadLink.click();

                   // 清理并移除下载链接
                   URL.revokeObjectURL(downloadLink.href);
                   document.body.removeChild(downloadLink);
                 }
               }
               })
      }
    },

    //生成杯子id
    createIdBtn(){
      axios.get(this.$store.state.localhost + 'common/generateid', {
        params: {

        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.createId=res.data.data
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },

    //单个杯子维护记录跳转到输入的页面
    deviceChangePagenumber() {
      if (this.pageMsg <= parseInt(this.data.pages) && this.pageMsg.length !== 0) {
        this.deviceRefresh(this.pageMsg)
        this.pagenumber = this.pageMsg;
      } else {
        this.$message({
          message: '页码不存在',
          type: 'error',
          offset: 300
        });
      }
      this.pageMsg = '';
    },


    //设备信息下一页跳转请求
    deviceChangePagedown(pagenumber) {
      if (pagenumber === parseInt(this.data.pages)) {
        return;
      }
      this.pagenumber++;
      this.deviceRefresh()
    },

    //设备信息上一页跳转请求
    deviceChangePageup(pagenumber) {
      if (pagenumber === 1) {
        return;
      }
      this.pagenumber--;
      this.deviceRefresh()
    },


    //点击提交按钮触发的请求
    editDeviceHand() {
      axios.post(this.$store.state.localhost + 'device/edit', {
            id: this.selectData.id,
            cupType: this.editDeviceValue1,
            oilType: this.editDeviceValue2,
            shift: this.editDeviceValue3,
            threshold: this.selectData.threshold
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.editDevice = !this.editDevice
          this.editDeviceValue1 = ''
          this.editDeviceValue2 = ''
          this.editDeviceValue3 = ''
          this.deviceRefresh()
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },

    //点击编辑发送的请求
    selectById() {
      axios.get(this.$store.state.localhost + 'device', {
        params: {
          id: this.data.records[this.editDeviceIndex].id,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.selectData = res.data.data
          this.editDeviceValue1 = this.selectData.cupType
          this.editDeviceValue2 = this.selectData.oilType
          this.editDeviceValue3 = this.selectData.shift
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //编辑设备信息按钮
    editDeviceMSg(index) {
      this.editDeviceIndex = index
      this.selectById()
      this.editDevice = !this.editDevice
    },

    //初始页面请求
    deviceRefresh() {
      axios.get(this.$store.state.localhost + 'device/page', {
        params: {
          page: this.pagenumber,
          pageSize: 14,
          terminalName: this.terminalName,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.data = res.data.data
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    }
  }
}
</script>

<style scoped>
#deviceBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 2000px;
  background: black;
}

#tab1 {
  position: absolute;
  width: 1524px;
  height: 750px;
  left: 197px;
  top: 240px;
}

#bacDevice {
  position: absolute;
  width: 1524px;
  height: 0;
  left: 198px;
  top: 200px;

  border: 1px solid #555555;
}

#p2 {
  position: relative;
  width: 20px;
  height: 20px;
  left: 40px;
  top: 4px;
}

#btnDevice {
  margin-left: -20px;
  padding-left: 10px;
  justify-content: center;

  width: 140px;
  height: 34px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;

  border-radius: 4px;
}

.btnDevice {
  background: #3A61F6;
}

.btnDevice:hover {
  background-color: #4cc9f0;
}


#searchDeviceId {
  box-sizing: border-box;

  padding-left: 20px;

  position: absolute;
  width: 400px;
  height: 38px;
  left: 1321px;
  top: 143px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;
  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}


#editDevicebac1 {
  position: absolute;
  width: 374px;
  height: 500px;
  left: 773px;
  top: 327px;

  background: #F3F3F5;
  border-radius: 12px;
}

#editDevicea1 {
  position: absolute;
  width: 200px;
  height: 25px;
  left: 30px;
  top: 20px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#editDevicea2 {
  position: absolute;
  width: 70px;
  height: 18px;
  left: 30px;
  top: 85px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#editDevicea3 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 171px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#editDevicea4 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 30px;
  top: 252px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;


  color: #3C3C3C;
}

#editDevicea5 {
  position: absolute;
  width: 56px;
  height: 18px;
  left: 195px;
  top: 252px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#editDevicea6 {
  position: absolute;
  width: 100px;
  height: 18px;
  left: 30px;
  top: 330px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#editDevicea7 {
  position: absolute;
  width: 100px;
  height: 18px;
  left: 195px;
  top: 330px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}


#editDeviceip1 {
  position: absolute;
  width: 160px;
  height: 36px;
  left: 30px;
  top: 114px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  padding-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #3A61F6;

  background: #E1E6FC;
  border-radius: 10px;
  border: none;
}

#editDeviceip2 {
  position: absolute;
  width: 50px;
  height: 36px;
  left: 30px;
  top: 200px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  padding-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #3A61F6;

  background: #E1E6FC;
  border-radius: 10px;
  border: none;
}

#editDeviceip3 {
  position: absolute;
  width: 137px;
  height: 46px;
  left: 20px;
  top: 276px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  padding-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #68696B;
}

.editDeviceip3 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.editDeviceip3:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#editDeviceip4 {
  position: absolute;
  width: 137px;
  height: 46px;
  left: 185px;
  top: 276px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  padding-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #68696B;
}


#editDeviceip5 {
  position: absolute;
  width: 137px;
  height: 46px;
  left: 20px;
  top: 360px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  padding-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #68696B;
}


#editDeviceip6 {
  position: absolute;
  width: 137px;
  height: 40px;
  left: 195px;
  top: 358px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  padding-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #68696B;
}


.editDeviceip6 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.editDeviceip6:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#editDevicebtn1 {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 427px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.1em;

  color: rgba(38, 41, 45, 0.6);

  background: #E9E9E9;
  border-radius: 4px;
  border: none;
}

#editDevicebtn2 {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 427px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.1em;

  color: #FFFFFF;

  background: #3A61F6;
  border-radius: 4px;
}

#editDevicep1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 20px;
}


#searchDeviceImg1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 1688px;
  top: 148px;
}


/*页码样式*/
#returnBackPage {
  position: absolute;
  left: -400px;
  top: 0;
}

#changgeLogPg1 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 1158px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#changgeLogPg2 {
  position: absolute;
  width: 24px;
  height: 25px;
  left: 1196px;
  top: 1058px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #777777;
}

#changgeLogPg3 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 1235px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#changgeLogPg4 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 1301px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#changgeLogPg5 {
  position: absolute;
  width: 12px;
  height: 25px;
  left: 1376px;
  top: 1058px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #FFFFFF;
}

#changgeLogPg6 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 1415px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#changgeLogPg7 {
  position: absolute;
  width: 32px;
  height: 20px;
  left: 1513px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#changgeLogPg8 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 1600px;
  top: 1062px;


  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

.changgeLogPage {
  position: absolute;
  width: 60px;
  height: 16px;
  left: 1550px;
  top: 1062px;

  color: #4cc9f0;
  background: black;
  border-style: none;
}

#changgeLogip1 {
  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  outline: none;
}


/*表格通用样式*/
tr:nth-child(odd) {
  background-color: #000000;
}

tr:nth-child(even) {
  background-color: #101010;
}

th {
  background: #222222;
  height: 50px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #444444;
  height: 50px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

}

.personcolor {
  color: #FFFFFF;
}

.personcolor:hover {
  color: #5E97FF;
}


/*生成id样式*/
#createId {
  position: absolute;
  width: 500px;
  height: 38px;
  left: 198px;
  top: 143px;

  box-sizing: border-box;
  padding-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;
  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}


#createIdBtn {
  margin-left: -20px;
  padding-left: 10px;
  justify-content: center;
  position: absolute;
  left: 730px;
  top: 145px;
  width: 100px;
  height: 34px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;

  border-radius: 4px;
  outline: none;
}

.createIdBtn {
  background: #3A61F6;
}

.createIdBtn:hover {
  background-color: #4cc9f0;
}



#createIdBtn1 {
  margin-left: -20px;
  padding-left: 10px;
  justify-content: center;
  position: absolute;
  left: 840px;
  top: 145px;
  width: 100px;
  height: 34px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;

  border-radius: 4px;
  outline: none;
}

.createIdBtn1 {
  background: #3A61F6;
}

.createIdBtn1:hover {
  background-color: #4cc9f0;
}
</style>