<template>
  <div id="headBackground">
    <div @click="changeCode(1)"
         :class="{box:this.$store.state.currentIndex===1,box2:this.$store.state.currentIndex!==1}">
      <router-link :to="{path:'/EndpointMonitoring'}" tag="p">
        <div id="a1">·</div>
        <div id="a2">
          端点监控
        </div>
      </router-link>
    </div>

    <div @click="changeCode(2)"
         :class="{box:this.$store.state.currentIndex===2,box2:this.$store.state.currentIndex!==2}">
      <router-link :to="{path:'/stockControl'}" tag="p">
        <div id="a3">·</div>
        <div id="a14">
          库存管理
        </div>
      </router-link>
    </div>

    <div @click="changeCode(3)"
         :class="{box:this.$store.state.currentIndex===3,box2:this.$store.state.currentIndex!==3}">
      <router-link :to="{path:'/Personnel'}" tag="p">
        <div id="a5">·</div>
        <div id="a6">
          人员管理
        </div>
      </router-link>
    </div>

    <div @click="changeCode(4)"
         :class="{box:this.$store.state.currentIndex===4,box2:this.$store.state.currentIndex!==4}">
      <router-link :to="{path:'/Device'}" tag="p">
        <div id="a7">·</div>
        <div id="a8">
          设备信息
        </div>
        <div id="a9">·</div>
      </router-link>
    </div>


    <div @click="changeCode(5)"
         :class="{box:this.$store.state.currentIndex===5,box2:this.$store.state.currentIndex!==5}">
      <router-link :to="{path:'/operationLog'}" tag="p">
        <div id="a10">·</div>
        <div id="a11">
          操作记录
        </div>
        <div id="a12">·</div>
      </router-link>
    </div>


    <!--右侧抽屉设置-->
    <!--    <img src="../assets/personnelInformation.png" alt="" id="personnelInformation">-->
    <img src="../assets/drawer.png" alt="" id="drawer" @click="drawerClick">

    <el-drawer
        z-index="1"
        size="208px"
        title="我是标题"
        :visible.sync="drawer"
        :modal="false"
        :modal-append-to-body="false"
        :with-header="false">
      <img src="../assets/a1.png" alt="" id="drawerImg">
      <div id="drawerText1">{{ personnelMessageEditData.name }}</div>
      <div id="drawerText2" v-if="personnelMessageEditData.post===1">仓库管理</div>
      <div id="drawerText2" v-if="personnelMessageEditData.post===2">维护工</div>
      <div id="drawerText2" v-if="personnelMessageEditData.post===3">设备管理</div>
      <div id="drawerText2" v-if="personnelMessageEditData.post===4">工程师</div>
      <div id="drawerText2" v-if="personnelMessageEditData.post===5">员工管理</div>
      <div id="drawerText2" v-if="personnelMessageEditData.post===6">超级管理员</div>
      <div id="drawerBg"></div>
      <button id="drawerBtn1" class="drawerBtn1" @click="personnelSetGet()">
        <img src="../assets/Settings_normal.png" alt="" style="position: relative;
width: 20px;
height: 20px;
left: -10px;
top: 4px;
">规则设置
      </button>
      <button id="drawerBtn2" class="drawerBtn2" @click="personnelMessageEdit">
        <img src="../assets/User_Circle.png" alt="" style="position: relative;
width: 20px;
height: 20px;
left: -10px;
top: 4px;
">编辑信息
      </button>
      <button id="drawerBtn3" class="drawerBtn3" @click="personnelPasswordEditStatus=!personnelPasswordEditStatus">
        <img src="../assets/Edit_Pen.png" alt="" style="position: relative;
width: 20px;
height: 20px;
left: -10px;
top: 4px;
">修改密码
      </button>
      <button id="drawerBtn4" class="drawerBtn4" @click="personnelLoginExitStatus=!personnelLoginExitStatus">
        <img src="../assets/Exit.png" alt="" style="position: relative;
width: 20px;
height: 20px;
left: -10px;
top: 4px;
">退出登录
      </button>

    </el-drawer>

    <!--    规则设置具体弹窗信息-->
    <div id="personnelSetBg1" v-if="personnelSet">
      <div id="personnelSetMsg1">规则设置</div>
      <div id="personnelSetBg2">
        <div id="personnelSetMsg2">当前字段</div>
      </div>
     <div id="personnelSetBg3">
      <div id="personnelSetMsg3">为{{personnelMessageSetData[containerIndex].layerName}}添加选项</div>
      </div>
      <div id="personnelSetBg4">
        <div style="position: absolute;left: 12px;" >
          <table >
            <tr v-for="(kind,index) in personnelMessageSetData" @click="containerIndexSet(index)" >
              <text-setting :msg="personnelMessageSetData" :index="index"></text-setting>
            </tr>

          </table>

        </div>
      </div>

      <div id="personnelSetBg5">

        <input type="text" id="personnelSetInputAdd" v-model="personnelSetInputAdd" class="personnelSetInputAdd">
        <div id="personnelSetBg6" class="container">

       <div id="row">
          <div class="row">
            <div  v-for="(item, index) in personnelMessageSetData[containerIndex].layerContent" v-if="containerIndex!==''">
              <!-- 组件内容 -->
              <containerSet :msg="personnelMessageSetData[containerIndex].layerContent" :index="index"></containerSet>
            </div>
          </div>
        </div>
        </div>
        <button id="personnelSetBtn" class="personnelSetBtn" @click="personnelSetBtnAdd()">添加</button>
      </div>

      <div @click="personnelSetBtnCancel()">
        <img src="../assets/Close_L.png" alt="" style="position: absolute;width: 28px;
         height: 28px;left: 1099px;top: 21px">
      </div>
      <button id="personnelSetBtn2" @click="personnelSetBtnCancel()">取消</button>
      <button id="personnelSetBtn3" @click="personnelSetBtnHand()" class="personnelSetBtn3">提交</button>
    </div>


    <!--编辑信息具体信息-->
    <div id="personnelMessageEditBg" v-if="personnelMessageEditStatus">
      <div id="personnelMessageEditText1">编辑信息</div>
      <div id="personnelMessageEditText2">手机</div>
      <div id="personnelMessageEditText3">邮箱</div>
      <img src="../assets/XDark.png" alt="" id="personnelMessageEditImg"
           @click="personnelMessageEditCancel">

      <input type="text" id="personnelMessageEditInput1" class="personnelMessageEditInput1"
             v-model="personnelMessageEditMsg1">
      <input type="text" id="personnelMessageEditInput2" class="personnelMessageEditInput2"
             v-model="personnelMessageEditMsg2">
      <el-upload id="personnelMessageEditImg1"
                 action="https://api.yiyenanjing.com/common/upload"
                 :headers="headerObj"
                 list-type="picture-card"
                 :show-file-list="false"
                 :before-upload="beforeUpload"
                 :on-success="handleSuccess"
                 ref="upload"
      >
        <img v-if="imageUrl" :src="imageUrl">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>

      <input ref="fileInput" type="file" style="display: none" @change="handleFileChange">
      <button id="personnelMessageEditBtn2" @click="personnelMessageEditCancel">取消
      </button>
      <button id="personnelMessageEditBtn3" @click="personnelMessageEditHand" class="personnelMessageEditBtn3">提交
      </button>
    </div>


    <!--修改密码具体信息-->
    <div id="personnelPasswordEditBg" v-if="personnelPasswordEditStatus">
      <div id="personnelPasswordEditText1">修改密码</div>
      <div id="personnelPasswordEditText2" v-if="personnelPasswordEditCss">请输入旧密码</div>
      <div id="personnelPasswordEditText22" v-if="!personnelPasswordEditCss">请输入正确的密码</div>
      <div id="personnelPasswordEditText3">请输入新密码</div>
      <div id="personnelPasswordEditText4" v-if="personnelPasswordEditCss1">确认新密码</div>
      <div id="personnelPasswordEditText44" v-if="!personnelPasswordEditCss1">两次输入的密码不一致</div>
      <img src="../assets/XDark.png" alt="" id="personnelPasswordEditImg" @click="personnelPasswordEditCancel">
      <input type="password" id="personnelPasswordEditInput1" class="personnelPasswordEditInput1"
             v-model="personnelPasswordEditMsg1" @blur="personnelPasswordEdit"
             @keyup.enter="personnelPasswordEdit" v-if="personnelPasswordEditCss">
      <input type="password" id="personnelPasswordEditInput11" class="personnelPasswordEditInput11"
             v-model="personnelPasswordEditMsg1" @blur="personnelPasswordEdit"
             @keyup.enter="personnelPasswordEdit" v-if="!personnelPasswordEditCss">
      <input type="password" id="personnelPasswordEditInput2" class="personnelPasswordEditInput2"
             v-model="personnelPasswordEditMsg2" @blur="personnelPasswordEdit1"
             @keyup.enter="personnelPasswordEdit1">
      <input type="password" id="personnelPasswordEditInput3" class="personnelPasswordEditInput3"
             v-model="personnelPasswordEditMsg3" @blur="personnelPasswordEdit1"
             @keyup.enter="personnelPasswordEdit1" v-if="personnelPasswordEditCss1">
      <input type="password" id="personnelPasswordEditInput33" class="personnelPasswordEditInput33"
             v-model="personnelPasswordEditMsg3" @blur="personnelPasswordEdit1"
             @keyup.enter="personnelPasswordEdit1" v-if="!personnelPasswordEditCss1">
      <button id="personnelPasswordEditBtn1" @click="personnelPasswordEditCancel">取消</button>
      <button id="personnelPasswordEditBtn2" class="personnelPasswordEditBtn22"
              v-if="!(personnelPasswordEditCss===true&&personnelPasswordEditMsg2!==''&&personnelPasswordEditMsg3!==''&&personnelPasswordEditMsg2===personnelPasswordEditMsg3)">
        提交
      </button>
      <button id="personnelPasswordEditBtn2" class="personnelPasswordEditBtn2" @click="personnelPasswordEditHand"
              v-if="personnelPasswordEditCss===true&&personnelPasswordEditMsg2!==''&&personnelPasswordEditMsg3!==''&&personnelPasswordEditMsg2===personnelPasswordEditMsg3">
        提交
      </button>
    </div>


    <!--退出登录具体信息-->
    <div id="personnelLoginExitBg" v-if="personnelLoginExitStatus">
      <div id="personnelLoginExitText1">退出登录</div>
      <div id="personnelLoginExitText2">确定要退出吗？</div>
      <button id="personnelLoginExitBtn1" @click="personnelLoginExitStatus=!personnelLoginExitStatus">取消</button>
      <router-link to="" tag="button" id="personnelLoginExitBtn2" @click.native="personnelLoginExitHand"
                   class="personnelLoginExitBtn2">提交
      </router-link>
    </div>


      <!--如果密码为123456提示修改密码-->
    <div id="personnelLoginExitBg" v-if="$store.state.password">
      <div id="personnelLoginExitText1">提示</div>
      <div id="personnelLoginExitText2">请修改密码</div>
      <button id="personnelLoginExitBtn3"  class="personnelLoginExitBtn2" @click="$store.state.password=!$store.state.password">确定</button>
    </div>





    <div id="b1"></div>

  </div>

</template>

<script>

import axios from "@/components/api.js";
import textSetting from "@/components/textSetting";
import containerSet from "@/components/containerSet";

export default {
  name: "tit",
  components: {textSetting,containerSet},
  data() {
    return {
      personnelSetInputAdd:'',
      containerIndex:0,
      personnelMessageSetData: [],
      personnelSetInpStatus: true,
      personnelSetImgStatus: true,
      personnelSet: false,
      imageUrl: '',
      listLength: 0,
      dialogImageUrl: '',
      dialogVisible: false,
      token: '',
      headerObj: {
        token: localStorage.getItem('token')
      },

      // headcode: 1,
      //  抽屉打开按钮事件
      personnelPasswordEditCss: true,
      personnelPasswordEditCss1: true,
      drawer: false,
      personnelMessageEditStatus: false,
      personnelPasswordEditStatus: false,
      personnelLoginExitStatus: false,
      personnelMessageEditMsg1: '',
      personnelMessageEditMsg2: '',
      personnelPasswordEditMsg1: '',
      personnelPasswordEditMsg2: '',
      personnelPasswordEditMsg3: '',
      personnelMessageEditData: '',
    }
  },

  watch: {},
  methods: {
    // //点击加号增加输入信息
    // personnelSetInputAddData(){
    //   this.personnelMessageSetData.push({
    //     "layerName":"",
    //     "layerContent": [],
    //     "isHighlighted": false,
    //   })
    // },

    //规则设置中提交按钮
    personnelSetBtnHand(){
      for (let i = 0; i < this.personnelMessageSetData.length; i++) {
        this.personnelMessageSetData[i].personnelSetImgStatus=false;
        this.personnelMessageSetData[i].highlighted=false;
      }
      axios.post(this.$store.state.localhost + 'common/savedistrict', this.personnelMessageSetData, {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelSet=!this.personnelSet
          this.containerIndex=0
          this.$store.state.msgDecideIndex=false
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },

    //规则设置中取消按钮
    personnelSetBtnCancel(){
      this.personnelSet=!this.personnelSet
      this.containerIndex=0
      for (let i = 0; i < this.personnelMessageSetData.length; i++) {
        this.personnelMessageSetData[i].personnelSetImgStatus=false;
        this.personnelMessageSetData[i].highlighted=false;
        console.log(this.personnelMessageSetData);
      }
    },


    //点击规则设置中具体字段添加按钮
    personnelSetBtnAdd(){
      if (this.personnelSetInputAdd!==""&& this.containerIndex!==""){
        this.personnelMessageSetData[this.containerIndex].layerContent.push(this.personnelSetInputAdd)
        this.personnelSetInputAdd=''
      }
    },


    //点击对应字段触发的方法
    containerIndexSet(index){
      this.containerIndex=index
    },



    //点击规则设置触发的方法
    personnelSetGet() {
      this.personnelSet = !this.personnelSet
      axios.get(this.$store.state.localhost +'common/district', {
        params: {},
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.personnelMessageSetData = res.data.data
          console.log(res.data);
          console.log(this.containerIndex);
          this.personnelMessageSetData[this.containerIndex].highlighted=true
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //规则设置中点击编辑按钮聚焦输入框的方法
    personnelSetImgStatusDecide() {
      this.personnelSetImgStatus = !this.personnelSetImgStatus;
      if (!this.personnelSetImgStatus) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },


    //图片格式校验
    beforeUpload() {

    },

    //上传图片方法
    handleSuccess(response, file, fileList) {
      this.imageUrl = response.data.imageUrl;
    },
    handleRemove(file, fileList) {
      this.listLength = fileList.length
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //点击抽屉触发方法
    drawerClick() {
      this.drawer = true
      axios.get(this.$store.state.localhost + 'employee/me', {
        params: {},
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code === 1) {
          this.personnelMessageEditData = res.data.data
          this.personnelMessageEditMsg1 = this.personnelMessageEditData.phone
          this.personnelMessageEditMsg2 = this.personnelMessageEditData.email
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //再次输入新密码
    personnelPasswordEdit1() {
      if (this.personnelPasswordEditMsg2 === this.personnelPasswordEditMsg3) {
        this.personnelPasswordEditCss1 = true
      } else {
        this.personnelPasswordEditCss1 = false
      }
    },


    //输入旧密码校验
    personnelPasswordEdit() {
      axios.post(this.$store.state.localhost + 'employee/oldpassword', {
            password: this.personnelPasswordEditMsg1
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelPasswordEditCss = true
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
          this.personnelPasswordEditCss = false
        }
      })
    },

    //打开编辑信息触发的方法
    personnelMessageEdit() {
      this.personnelMessageEditStatus = !this.personnelMessageEditStatus
    },


    imitate() {
      this.$router.push('/imitate')
    },

    //退出登录弹窗中的提交按钮方法
    personnelLoginExitHand() {
      axios.post(this.$store.state.localhost + 'employee/logout', {},
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.$router.push('/register')
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //修改密码中的提交按钮方法
    personnelPasswordEditHand() {
      axios.post(this.$store.state.localhost + 'employee/me', {
            password: this.personnelPasswordEditMsg3,
            phone: this.personnelMessageEditMsg1,
            email: this.personnelMessageEditMsg2,
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
          this.personnelLoginExitHand()
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //修改密码中的取消和X按钮方法
    personnelPasswordEditCancel() {
      this.personnelPasswordEditStatus = !this.personnelPasswordEditStatus
      this.personnelPasswordEditMsg1 = ''
      this.personnelPasswordEditMsg2 = ''
      this.personnelPasswordEditMsg3 = ''
    },


    //编辑信息中的点击上传按钮方法
    personnelMessageEditDownload() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file1 = event.target.files[0];

      // 创建FormData对象
      const formData = new FormData();
      formData.append('file', file1); // 将文件添加到formData对象中
      axios.post(this.$store.state.localhost + 'common/upload', {
            file: formData,
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //编辑信息中的提交按钮方法
    personnelMessageEditHand() {
      axios.post(this.$store.state.localhost + 'employee/me', {
            phone: this.personnelMessageEditMsg1,
            email: this.personnelMessageEditMsg2,
          },
          {
            headers: {
              token: localStorage.getItem('token')
            }
          }).then((res) => {
        if (res.data.code === 1) {
          this.personnelMessageEditStatus = !this.personnelMessageEditStatus

          this.$message({
            message: res.data.data,
            type: 'success',
            offset: 300,
          })
        }
        if (res.data.code === 0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //编辑信息中的取消和X的按钮方法
    personnelMessageEditCancel() {
      this.personnelMessageEditStatus = !this.personnelMessageEditStatus
    },


    changeCode(index) {
      // this.headcode = index
      this.$store.commit("headIndexChange", index)

    }
  }
}
</script>

<style scoped>
#headBackground {
  position: fixed;
  width: 1920px;
  height: 100px;
  background-color: black;
  z-index: 4;
}

.box {
  width: 150px;
  height: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;

  color: #3A61F6;
}

.box2 {
  width: 150px;
  height: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;

  color: #FFFFFF;
}

#a1 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 197px;
  top: 36px;
}

#a2 {
  position: fixed;
  height: 25px;
  left: 214px;
  top: 36px;
}

#a3 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 314px;
  top: 36px;
}

#a14 {
  position: fixed;
  height: 25px;
  left: 330px;
  top: 36px;
}

#a5 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 430px;
  top: 36px;

}

#a6 {
  position: fixed;
  height: 25px;
  left: 446px;
  top: 36px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
}

#a7 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 546px;
  top: 36px;
}

#a8 {
  position: fixed;
  height: 25px;
  left: 562px;
  top: 36px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
}

#a9 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 662px;
  top: 36px;
}


#a10 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 662px;
  top: 36px;
}

#a11 {
  position: fixed;
  height: 25px;
  left: 678px;
  top: 36px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
}

#a12 {
  position: fixed;
  width: 3px;
  height: 3px;
  left: 778px;
  top: 36px;
}


#b1 {
  position: fixed;
  width: 1920px;
  height: 0;
  left: 0;
  top: 100px;

  border: 1px solid #555555
}


/*抽屉设置*/
#drawer {
  position: absolute;
  width: 40px;
  height: 30px;
  left: 1805px;
  top: 35px;
}

#personnelInformation {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 1743px;
  top: 31px;
}

:deep(.el-drawer.rtl) {
  background: black;
}

#drawerImg {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 36px;
  top: 31px;
}

#drawerText1 {
  position: absolute;
  width: 100px;
  height: 20px;
  left: 36px;
  top: 85px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

#drawerText2 {
  position: absolute;
  width: 100px;
  height: 18px;
  left: 36px;
  top: 109px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #8D8D8D;
}

#drawerBg {
  position: absolute;
  width: 34px;
  height: 1px;
  left: 36px;
  top: 147px;

  background: #3A61F6;
}

#drawerBtn1 {
  position: absolute;
  width: 208px;
  height: 44px;
  left: -17px;
  top: 158px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

.drawerBtn1 {
  background: black;
}

.drawerBtn1:hover {
  background: #3A61F6;
}

#drawerBtn2 {
  position: absolute;
  width: 208px;
  height: 44px;
  left: -17px;
  top: 202px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

.drawerBtn2 {
  background: black;
}

.drawerBtn2:hover {
  background: #3A61F6;
}

#drawerBtn3 {
  position: absolute;
  width: 208px;
  height: 44px;
  left: -17px;
  top: 246px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

.drawerBtn3 {
  background: black;
}

.drawerBtn3:hover {
  background: #3A61F6;
}


#drawerBtn4 {
  position: absolute;
  width: 208px;
  height: 44px;
  left: -17px;
  top: 290px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

.drawerBtn4 {
  background: black;
}

.drawerBtn4:hover {
  background: #3A61F6;
}


/*编辑信息样式*/
#personnelMessageEditBg {
  z-index: 2;
  position: absolute;
  width: 374px;
  height: 588px;
  left: 773px;
  top: 246px;

  background: #F3F3F5;
  border-radius: 12px;
}

#personnelMessageEditText1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 19px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#personnelMessageEditText2 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 324px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#personnelMessageEditText3 {
  position: absolute;
  width: 28px;
  height: 18px;
  left: 30px;
  top: 410px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#personnelMessageEditImg {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 19px;
}

#personnelMessageEditInput1 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 348px;
}

.personnelMessageEditInput1 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.personnelMessageEditInput1:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#personnelMessageEditInput2 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 434px;
}

.personnelMessageEditInput2 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.personnelMessageEditInput2:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#personnelMessageEditBtn1 {
  position: absolute;
  width: 100px;
  height: 40px;
  left: 137px;
  top: 244px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;


  border-radius: 4px;
  border-style: hidden;

  background: #3A61F6;
}

#personnelMessageEditBtn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 525px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: rgba(38, 41, 45, 0.6);

  background: #E9E9E9;
  border-radius: 4px;
  border-style: hidden;
}

#personnelMessageEditBtn3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 525px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;


  border-radius: 4px;
  border-style: hidden;
}

.personnelMessageEditBtn3 {
  background: #3A61F6;
}

.personnelMessageEditBtn3:hover {
  background: #4cc9f0;
}



#personnelSetBtn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 434px;
  top:710px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: rgba(38, 41, 45, 0.6);

  background: #E9E9E9;
  border-radius: 4px;
  border-style: hidden;
}

#personnelSetBtn3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 546px;
  top:710px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;


  border-radius: 4px;
  border-style: hidden;
}

.personnelSetBtn3 {
  background: #3A61F6;
}

.personnelSetBtn3:hover {
  background: #4cc9f0;
}






/*修改密码弹窗的css*/
#personnelPasswordEditBg {
  z-index: 2;
  position: absolute;
  width: 374px;
  height: 434px;
  left: 773px;
  top: 246px;

  background: #F3F3F5;
  border-radius: 12px;
}

#personnelPasswordEditText1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 19px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#personnelPasswordEditText2 {
  position: absolute;
  width: 84px;
  height: 18px;
  left: 30px;
  top: 84px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#personnelPasswordEditText22 {
  position: absolute;
  width: 200px;
  height: 18px;
  left: 30px;
  top: 84px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FF5345;
}

#personnelPasswordEditText3 {
  position: absolute;
  width: 84px;
  height: 18px;
  left: 30px;
  top: 170px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#personnelPasswordEditText4 {
  position: absolute;
  width: 70px;
  height: 18px;
  left: 30px;
  top: 256px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}


#personnelPasswordEditText44 {
  position: absolute;
  width: 200px;
  height: 18px;
  left: 30px;
  top: 256px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FF5345;
}


#personnelPasswordEditImg {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 326px;
  top: 19px;
}

#personnelPasswordEditInput1 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 108px;
}

.personnelPasswordEditInput1 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.personnelPasswordEditInput1:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#personnelPasswordEditInput11 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 108px;
}

.personnelPasswordEditInput11 {
  background: #FEFDFD;
  outline: none;

  border-bottom: 2px solid #FF5345;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#personnelPasswordEditInput2 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 194px;
}

.personnelPasswordEditInput2 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.personnelPasswordEditInput2:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}

#personnelPasswordEditInput3 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 280px;
}

.personnelPasswordEditInput3 {
  background: #FEFDFD;
  border: 1px solid rgba(38, 41, 45, 0.1);
  border-radius: 6px;
  outline: none;
}

.personnelPasswordEditInput3:focus {
  border-bottom: 2px solid #3A61F6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#personnelPasswordEditInput33 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 314px;
  height: 46px;
  left: 30px;
  top: 280px;
}

.personnelPasswordEditInput33 {
  background: #FEFDFD;
  outline: none;

  border-bottom: 2px solid #FF5345;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 6px 6px 0 0;
}


#personnelPasswordEditBtn1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 371px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: rgba(38, 41, 45, 0.6);

  background: #E9E9E9;
  border-radius: 4px;
  border-style: none;
}

#personnelPasswordEditBtn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 371px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;


  border-radius: 4px;
  border-style: none;
}


.personnelPasswordEditBtn22 {
  background: #93A9FF;
}


.personnelPasswordEditBtn2 {
  background: #3A61F6;
}


/*退出登录弹窗的css*/
#personnelLoginExitBg {
  z-index: 2;
  position: absolute;
  width: 374px;
  height: 178px;
  left: 773px;
  top: 440px;

  background: #F3F3F5;
  border-radius: 12px;
}

#personnelLoginExitText1 {
  position: absolute;
  width: 80px;
  height: 25px;
  left: 30px;
  top: 20px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #212121;
}

#personnelLoginExitText2 {
  position: absolute;
  width: 98px;
  height: 18px;
  left: 30px;
  top: 55px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #3C3C3C;
}

#personnelLoginExitBtn1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 81px;
  top: 115px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: rgba(38, 41, 45, 0.6);

  background: #E9E9E9;
  border-radius: 4px;
  border-style: none;
}

#personnelLoginExitBtn2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 193px;
  top: 115px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;


  border-radius: 4px;
  border-style: none;
}


#personnelLoginExitBtn3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  gap: 10px;

  position: absolute;
  width: 100px;
  height: 38px;
  left: 140px;
  top: 115px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #FFFFFF;


  border-radius: 4px;
  border-style: none;
}


.personnelLoginExitBtn2 {
  background: #3A61F6;
}

.personnelLoginExitBtn2:focus {
  background: #4cc9f0;
}


#personnelMessageEditImg1 {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 110px;
  top: 100px;
}


#personnelSetBg1 {
  z-index: 1;

  position: absolute;
  width: 1142px;
  height: 770px;
  left: 376px;
  top: 177px;

  background-color: #F1F1F4;
}

#personnelSetBg2 {
  position: absolute;
  width:342px;
  height: 48px;
  left: 0;
  top: 65px;

  background-color: #E9E9ED;
  border: 1px solid #D9D9D9;
}

#personnelSetBg3 {
  position: absolute;
  width: 797px;
  height: 48px;
  left: 342px;
  top: 65px;

  background-color: #E9E9ED;
  border: 1px solid #D9D9D9;
}

#personnelSetBg4 {
  position: absolute;
  width: 342px;
  height: 572px;
  left: 0;
  top: 113px;

  border: 1px solid #D9D9D9;
  background: #FFF;
}

#personnelSetBg5 {
  position: absolute;
  width: 797px;
  height: 572px;
  left: 342px;
  top: 113px;

  border: 1px solid #D9D9D9;
  background: #FFF;
}

#personnelSetBg6 {
  position: absolute;
  width: 704px;
  height: 452px;
  left: 48px;
  top: 96px;

  border-radius: 4px;
  border: 1px solid #CBCBCB;
  background: var(--white, #FEFDFD);
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

#row{
  left: 8px;
}


#personnelSetBtn{
  position: absolute;
  width: 100px;
  height: 50px;
  left: 266px;
  top: 24px;


  justify-content: center;
  align-items: center;
  border-radius: 4px;


  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.personnelSetBtn{
  background: #3A61F6;
}

.personnelSetBtn:hover{
  background: #31C0FF;
}

#personnelSetInputAdd {
  position: absolute;
  width: 200px;
  height: 46px;
  left: 48px;
  top: 24px;

  outline: none;
  padding-left: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 6px;

  background: var(--white, #FEFDFD);
}

.personnelSetInputAdd{
  border: 1px solid #CBCBCB;
}

.personnelSetInputAdd:focus{
  border: 1px solid #3A61F6;
}

#personnelSetMsg1 {
  position: absolute;
  left: 34px;
  top: 21px;
  color: #212121;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#personnelSetMsg2 {
  position: absolute;
  left: 12px;
  top: 15px;
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#personnelSetMsg3 {
  position: absolute;
  left: 12px;
  top: 15px;
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


</style>
