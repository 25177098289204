import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import axios from 'axios';


Vue.use(ElementUI);
import router from './router'
import store from './store'



// 设置拦截器
// axios.interceptors.response.use(
//     response => {
//
//       if (response.data.code === 401) {
//         // 处理401错误
//         router.push("/register")
//       }
//       // 请求成功，返回响应数据
//       return response.data;
//
//     },
//     error => {
//       // 请求失败，处理错误
//       if (error.response) {
//         // 存在响应，获取错误码
//         const statusCode = error.response.status;
//         console.log('Error status code:', statusCode);
//
//         // 根据错误码执行相应的操作
//         if (statusCode === 400) {
//           // 处理400错误
//         } else if (statusCode === 404) {
//           // 处理404错误
//         } else {
//           // 处理其他错误
//         }
//       }
//
//       return Promise.reject(error);
//     }
// );





Vue.config.productionTip = false

// 创建Vue实例，启动应用
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
