<template>
  <div id="personnelSetContainer" class="personnelSetContainer" v-if="index!==''">
     <div id="msgClass">{{msg[index]}}</div>
    <img src="../assets/Close_hover.png"   alt="" style="position: absolute;left: 84px;top: 8px;width: 24px;height: 24px"  @click="msgDelete(index)">
  </div>
</template>

<script>
export default {
  name: "containerSet",
  props: ['msg', 'index'],

  methods: {
    msgDelete(index){
      this.msg.splice(index,1)
    }
  }
}
</script>

<style scoped>
#personnelSetContainer{
  margin-left: 20px;
  margin-top: 12px;
  position: relative;
  width: 112px;
  height: 40px;

  background: #E1E6FC;
  border-radius: 8px;
  border: 1px solid #3A61F6;
}



#msgClass{
  position: absolute;
  left: 12px;
  top:10px;
  width:70px;
  height: 17px;
  color: #3A61F6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


</style>