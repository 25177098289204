import { render, staticRenderFns } from "./restorePerson.vue?vue&type=template&id=3de64136&scoped=true&"
import script from "./restorePerson.vue?vue&type=script&lang=js&"
export * from "./restorePerson.vue?vue&type=script&lang=js&"
import style0 from "./restorePerson.vue?vue&type=style&index=0&id=3de64136&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3de64136",
  null
  
)

export default component.exports