// api.js

import axios from 'axios';
import router from "@/router/index";

// // 请求拦截器
// axios.interceptors.request.use(
//     (config) => {
//       // 在请求发送之前做一些处理，比如设置请求头信息等
//       config.headers.Authorization = localStorage.getItem('token');
//       return config;
//     },
//     (error) => {
//       return Promise.reject(error);
//     }
// );

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
      // 在获取响应数据之后做一些处理
      if (response.data.code ===401) {
        // 如果请求返回401状态码，可以跳转到登录页面
        router.push('/register');
      }
      return response;
    },
    // (error) => {
    //   // 处理错误响应
    //   if (error.response) {
    //     if (error.response.status ===304) {
    //       // 如果请求返回403状态码，可以跳转到登录页面
    //      router.push('/register');
    //     }
    //   }
    //   return Promise.reject(error);
    // }
);

export default axios;