<template>
  <div id="operationLogBg">
    <div id="bac4"></div>
    <div id="a1" @click="onClick(1)">
      <img v-if="logcode===1" src="../assets/c1.png" id="p1" alt="">
      <img v-if="logcode!==1" src="../assets/c6.png" id="p1" alt="">
      <div id="a2" v-if="logcode===1"></div>
      <div :class="{a:logcode===1,b:logcode!==1}" id="b1">
        端点
      </div>
    </div>

    <div id="a3" @click="onClick(2)">
      <img v-if="logcode===2" src="../assets/c4.png" id="p1" alt="">
      <img v-if="logcode!==2" src="../assets/c2.png" id="p1" alt="">
      <div id="a2" v-if="logcode===2"></div>
      <div :class="{a:logcode===2,b:logcode!==2}" id="b1">
        库存
      </div>
    </div>

    <div id="a11" @click="onClick(3)">
      <img v-if="logcode===3" src="../assets/c5.png" id="p1" alt="">
      <img v-if="logcode!==3" src="../assets/c3.png" id="p1" alt="">
      <div id="a2" v-if="logcode===3"></div>
      <div :class="{a:logcode===3,b:logcode!==3}" id="b1">
        人员
      </div>
    </div>

    <div id="operationLogPremiss" @click="onClick(4)">
      <img v-if="logcode===4" src="../assets/c7.png" id="p1" alt="">
      <img v-if="logcode!==4" src="../assets/a4.png" id="p1" alt="">
      <div id="a2" v-if="logcode===4"></div>
      <div :class="{a:logcode===4,b:logcode!==4}" id="b1">
        权限
      </div>
    </div>

    <table id="tab1" style="height: 50px">
      <thead>
      <tr>
        <th style="width: 240px;">操作时间
        </th>
        <th style="width: 168px;">操作类型
        </th>
        <th style="width: 168px;">操作人工号
        </th>
        <th style="width: 168px;">操作人姓名
        </th>
        <th style="width: 168px;">操作对象
        </th>
        <th style="width: 612px;">操作日志
        </th>
      </tr>
      </thead>
      <tbody>

      <tr v-if="logcode===1" v-for="(kind,index) in data.records">
        <td style="width: 240px;" v-model="kind.operationTime">{{ kind.operationTime }}</td>
        <td style="width: 168px;" v-model="kind.operationType">{{ kind.operationType }}</td>
        <td style="width: 168px;" v-model="kind.operationId">{{ kind.operationId }}</td>
        <td style="width: 168px;" v-model="kind.operationName">{{ kind.operationName }}</td>
        <td style="width: 168px;" v-model="kind.cupId">{{ kind.cupId }}</td>
        <td style="width: 612px;" v-model="kind.operationContent">{{ kind.operationContent }}</td>
      </tr>

      <tr v-if="logcode===2" v-for="(kind,index) in data.records">
        <td style="width: 240px;" v-model="kind.operationTime">{{ kind.operationTime }}</td>
        <td style="width: 168px;" v-model="kind.operationType">{{ kind.operationType }}</td>
        <td style="width: 168px;" v-model="kind.operationId">{{ kind.operationId }}</td>
        <td style="width: 168px;" v-model="kind.operationName">{{ kind.operationName }}</td>
        <td style="width: 168px;" v-model="kind.cupType">{{ kind.cupType }}</td>
        <td style="width: 612px;" v-model="kind.operationContent">{{ kind.operationContent }}</td>
      </tr>

      <tr v-if="logcode===3" v-for="(kind,index) in data.records">
        <td style="width: 240px;" v-model="kind.operationTime">{{ kind.operationTime }}</td>
        <td style="width: 168px;" v-model="kind.operationType">{{ kind.operationType }}</td>
        <td style="width: 168px;" v-model="kind.operationId">{{ kind.operationId }}</td>
        <td style="width: 168px;" v-model="kind.operationName">{{ kind.operationName }}</td>
        <td style="width: 168px;" v-model="kind.employeeId">{{ kind.employeeId }}</td>
        <td style="width: 612px;" v-model="kind.operationContent">{{ kind.operationContent }}</td>
      </tr>

      <tr v-if="logcode===4" v-for="(kind,index) in data.records">
        <td style="width: 240px;" v-model="kind.operationTime">{{ kind.operationTime }}</td>
        <td style="width: 168px;" v-model="kind.operationType">{{ kind.operationType }}</td>
        <td style="width: 168px;" v-model="kind.operationId">{{ kind.operationId }}</td>
        <td style="width: 168px;" v-model="kind.operationName">{{ kind.operationName }}</td>
        <td style="width: 168px;" v-if="kind.postName===1">仓库管理</td>
        <td style="width: 168px;" v-if="kind.postName===2">维护工</td>
        <td style="width: 168px;" v-if="kind.postName===3">设备管理</td>
        <td style="width: 168px;" v-if="kind.postName===4">工程师</td>
        <td style="width: 168px;" v-if="kind.postName===5">员工管理</td>
        <td style="width: 168px;" v-if="kind.postName===6">超级管理员</td>
        <td style="width: 168px;" v-model="kind.operationContent">{{ kind.operationContent }}</td>
      </tr>
      </tbody>
    </table>



    <div id="inp1" v-if="logcode===1||logcode===4">
      <el-date-picker
          class="picker"
          size="small"
          @change="changeDate"
          v-model="value1"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
    </div>


<div id="inp2" v-if="logcode===1||logcode===4">
    <el-select v-model="value" placeholder="选择时间段" size="small" @change="changeDate">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
</div>


    <input v-if="logcode===1" type="text" placeholder="操作人工号" id="inp3" v-model="operationId"
           @blur="operationIdRefresh" @keyup.enter="operationIdRefresh">
    <input v-if="logcode===1" type="text" placeholder="操作人姓名" id="inp4" v-model="operationName"
           @blur="operationNameRefresh" @keyup.enter="operationNameRefresh">
    <input v-if="logcode===1" type="text" placeholder="油脂杯编号" id="inp5" v-model="cupId"
           @blur="cupIdRefresh" @keyup.enter="cupIdRefresh">





    <div id="dateTime" v-if="logcode===2||logcode===3">
      <el-date-picker
          class="picker"
          size="small"
          @change="changeDate"
          v-model="value1"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
      </el-date-picker>
    </div>


    <div id="timePeriod" v-if="logcode===2||logcode===3">
      <el-select v-model="value" placeholder="选择时间段" size="small" @change="changeDate">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>


<div id="inp2" v-if="logcode===2">
    <el-select v-model="operationTypeValue1" @change="changeDate" size="small">
      <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
</div>
    <input v-if="logcode===2" type="text" placeholder="操作人工号" id="inp3" v-model="operationId"
           @blur="operationIdRefresh" @keyup.enter="operationIdRefresh">
    <input v-if="logcode===2" type="text" placeholder="操作人姓名" id="inp4" v-model="operationName"
           @blur="operationNameRefresh" @keyup.enter="operationNameRefresh">
    <input v-if="logcode===2" type="text" placeholder="油脂杯型号" id="inp5" v-model="cupType"
           @blur="cupTypeRefresh" @keyup.enter="cupTypeRefresh">


    <div id="inp2" v-if="logcode===3">
      <el-select v-model="operationTypeValue" @change="changeDate" size="small">
        <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <input v-if="logcode===3" type="text" placeholder="操作人工号" id="inp3" v-model="operationId"
           @blur="operationIdRefresh" @keyup.enter="operationIdRefresh">
    <input v-if="logcode===3" type="text" placeholder="操作人姓名" id="inp4" v-model="operationName"
           @blur="operationNameRefresh" @keyup.enter="operationNameRefresh">
    <input v-if="logcode===3" type="text" placeholder="被操作人工号" id="inp5" v-model="employeeId"
           @blur="employeeIdRefresh" @keyup.enter="employeeIdRefresh">



    <div id="postName" v-if="logcode===4">
      <el-select v-model="postNameValue" @change="changeDate" size="small">
        <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
    <input v-if="logcode===4" type="text" placeholder="操作人工号" id="inp4" v-model="operationId"
           @blur="operationIdRefresh" @keyup.enter="operationIdRefresh">
    <input v-if="logcode===4" type="text" placeholder="操作人姓名" id="inp5" v-model="operationName"
           @blur="operationNameRefresh" @keyup.enter="operationNameRefresh">


    <!--人员管理页码-->
    <div>
      <div id="pg1">共</div>
      <div id="pg2" v-model="pagetotal=data.pages">{{ pagetotal }}</div>
      <div id="pg3">页</div>
      <div id="pg4" @click="pageup(pagenumber)">上一页</div>
      <div id="pg5" v-model="pagenumber">{{ data.current }}</div>
      <div id="pg6" @click="pagedown(pagenumber)">下一页</div>
      <div id="pg7">前往</div>
      <input type="text" class="Page" v-model="pageMsg" maxlength="4" id="ip1" @blur="toPageNumber()"
             @keyup.enter="toPageNumber()"
             onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
             onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9] /g,'')}else{this.value=this.value.replace(/\D/g,'')}">
      <div id="pg8">页</div>
    </div>


    <tit></tit>

  </div>
</template>

<script>
import tit from "@/components/tit";
import headmenu from "@/components/headmenu";
// import axios from "axios";
import axios from "@/components/api.js";

export default {
  components: {tit, headmenu},
  data() {
    return {
      logcode: 1, //标识符
      data: [],
      pagenumber: 1,
      pagetotal: '',
      pageMsg: '',
      value1: '',
      operationId: null,
      operationName: null,
      cupId: null,
      postName: null,
      cupType: null,
      employeeId:null,
      options: [{
        value: '1',
        label: '昨天'
      }, {
        value: '7',
        label: '7天'
      }, {
        value: '14',
        label: '14天'
      }, {
        value: '30',
        label: '30天'
      }, {
          value: null,
          label: '不限时间段'
        }],
      value:null,
      options1: [{
        value: '新增型号',
        label: '新增型号'
      }, {
        value: '修改阈值',
        label: '修改阈值'
      }, {
        value: '新增入库',
        label: '新增入库'
      }, {
        value: '库存校正',
        label: '库存校正'
      }, {
        value: '编辑型号',
        label: '编辑型号'
      }, {
        value: null,
        label: '不限操作类型'
      }],
      operationTypeValue1:null,
      options2: [{
        value: '新增员工',
        label: '新增员工'
      }, {
        value: '编辑员工',
        label: '编辑员工'
      }, {
        value: '禁用员工',
        label: '禁用员工'
      }, {
        value: '恢复员工',
        label: '恢复员工'
      } ,{
        value: null,
        label: '不限操作类型'
      }],
      operationTypeValue:null,
      options3:[{
        value: '1',
        label: '仓库管理'
      }, {
        value: '2',
        label: '维护工'
      }, {
        value: '3',
        label: '设备管理'
      }, {
        value: '4',
        label: '工程师'
      }, {
        value: '5',
        label: '员工管理'
      }, {
        value: null,
        label: '不限职务'
      }],
      postNameValue:null,
    }
  },
  mounted() {

  },
  created() {
    // location.reload()
    this.$store.commit("headIndexChange", 5)
    this.endpointRecord(1)
  },
  methods: {
    //被操作人工号查询方法
    employeeIdRefresh(){
      this.personnelOperationRecord()
    },
    //油脂杯型号查询方法
    cupTypeRefresh(){
      this.stockOperationRecord()
    },
    //油脂杯编号查询方法
    cupIdRefresh(){
      this.endPointOperationRecord()
    },
    //操作人姓名查询方法
    operationNameRefresh(){
      if(this.logcode===1){
        this.endPointOperationRecord()
      }
      if(this.logcode===2){
        this.stockOperationRecord()
      }
      if(this.logcode===3){
        this.personnelOperationRecord()
      }
      if(this.logcode===4){
        this.permissionOperationRecord()
      }
    },


    //操作人工号查询方法
    operationIdRefresh() {
      if(this.logcode===1){
      this.endPointOperationRecord()
      }
      if(this.logcode===2){
        this.stockOperationRecord()
      }
      if(this.logcode===3){
        this.personnelOperationRecord()
      }
      if(this.logcode===4){
        this.permissionOperationRecord()
      }
    },


    //端点操作记录刷新方法
    endPointOperationRecord() {
      axios.get(this.$store.state.localhost+'log/cup', {
        params: {
          page: this.pagenumber,
          pageSize: 14,
          operationId: this.operationId,
          operationName: this.operationName,
          cupId: this.cupId,
          internalDay: this.value,
          date:this.value1,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code===1) {
          this.data = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //库存操作记录刷新方法
    stockOperationRecord() {
      axios.get(this.$store.state.localhost+'log/stock', {
        params: {
          page: this.pagenumber,
          pageSize: 14,
          operationId:this.operationId,
          operationName:this.operationName,
          internalDay: this.value,
          date:this.value1,
          operationType:this.operationTypeValue1,
          cupType:this.cupType,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code===1) {
          this.data = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //人员操作记录刷新方法
    personnelOperationRecord() {
      axios.get(this.$store.state.localhost+'log/employee', {
        params: {
          page: this.pagenumber,
          pageSize: 14,
          operationId:this.operationId,
          operationName:this.operationName,
          internalDay: this.value,
          date:this.value1,
          operationType:this.operationTypeValue,
          employeeId:this.employeeId,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code===1) {
          this.data = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //权限操作记录刷新方法
    permissionOperationRecord() {
      axios.get(this.$store.state.localhost+'log/code', {
        params: {
          page: this.pagenumber,
          pageSize: 14,
          operationId:this.operationId,
          operationName:this.operationName,
          internalDay: this.value,
          date:this.value1,
          postName:this.postNameValue,
    },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code===1) {
          this.data = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },


    //选择日期的方法
    changeDate() {
      if (this.logcode===1) {
        this.endPointOperationRecord()
      }
      if (this.logcode === 2) {
        this.stockOperationRecord()
      }
      if (this.logcode === 3) {
        this.personnelOperationRecord()
      }
      if (this.logcode === 4) {
        this.permissionOperationRecord()
      }
    },


    //通用函数封装，使得分页和点击事件可以共用
    selectModule(index) {
      if (index === 1) {
        this.endPointOperationRecord()
      }
      if (index === 2) {
        this.stockOperationRecord()
      }
      if (index === 3) {
        this.personnelOperationRecord()
      }
      if (index === 4) {
        this.permissionOperationRecord()
      }
    },
    // 点击事件
    onClick(index) {
      // 赋值
          this.pagenumber = 1;
          this.internalDay = null;
          this.operationId = null;
          this.operationName = null;
          this.cupId = null;
          this.date = null;
          this.postName = null;
          this.operationType = null;
          this.cupType = null;
          this.postNameValue=null;
          this.operationTypeValue1=null;
          this.operationTypeValue=null;
          this.value=null;
          this.value1=null;
          this.logcode = index;
      this.selectModule(index)
    },
    //初始化页面显示
    endpointRecord(pagenumber) {
      axios.get(this.$store.state.localhost+'log/cup', {
        params: {
          page: pagenumber,
          pageSize: 14,
        },
        headers: {
          token: localStorage.getItem('token')
        }
      }).then((res) => {
        if (res.data.code===1) {
          this.data = res.data.data
        }
        if (res.data.code===0) {
          this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              }
          )
        }
      })
    },
    //上一页跳转请求
    pageup(pagenumber) {
      if (pagenumber === 1) {
        return;
      }
      this.pagenumber--;
      this.selectModule(this.logcode)
    },
    //下一页跳转请求
    pagedown(pagenumber) {
      if (pagenumber === parseInt(this.data.pages)) {
        return;
      }
      this.pagenumber++;
      this.selectModule(this.logcode)
    },
    //跳转到输入的页面
    toPageNumber() {
      if (this.pageMsg <= this.data.pages && this.pageMsg.length !== 0) {
        this.personnelRenew(this.pageMsg)
      } else {
        console.log(1111);
        this.$message({
          message: '页码不存在',
          type: 'error',
          offset: 300
        });
      }
    },
  }
}
</script>

<style scoped>
#operationLogBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 2000px;
  background: black;
}

.a {
  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #FFFFFF;
}

.b {
  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #8D8D8D;
}

#a1 {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 197px;
  top: 100px;
}

#a2 {
  position: absolute;
  width: 100px;
  height: 2px;
  left: 0;
  top: 100px;

  background: rgba(49, 192, 255, 1);
}

#p1 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 36px;
  top: 24px;
}

#b1 {
  position: absolute;
  width: 40px;
  height: 25px;
  left: 30px;
  top: 52px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

#a3 {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 297px;
  top: 100px;
}

#a11 {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 397px;
  top: 100px;
}

#operationLogPremiss {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 497px;
  top: 100px;
}

table {
  border-collapse: collapse;
}

th {
  justify-content: center;
  align-items: center;


  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;

  background: #222222;
  border: 1px solid #444444;
}

td {
  justify-content: center;
  align-items: center;

  height: 50px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

#bac4 {
  position: absolute;
  width: 1524px;
  height: 0;
  left: 197px;
  top: 200px;

  border: 1px solid #555555;
}

#tab1 {
  position: absolute;
  left: 197px;
  top: 240px;
  width: 1524px;
}

#inp1 {
  position: absolute;
  width: 149px;
  height: 38px;
  left: 866px;
  top: 145px;
}

#inp2 {
  position: absolute;
  width: 149px;
  height: 38px;
  left: 1096px;
  top: 145px;
}

#inp3 {
  box-sizing: border-box;
  padding-left: 20px;

  position: absolute;
  width: 149px;
  height: 38px;
  left: 1255px;
  top: 143px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;

  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}

#inp4 {
  box-sizing: border-box;
  padding-left: 20px;

  position: absolute;
  width: 149px;
  height: 38px;
  left: 1414px;
  top: 143px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;

  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}

#inp5 {
  box-sizing: border-box;
  padding-left: 20px;

  position: absolute;
  width: 149px;
  height: 38px;
  left: 1573px;
  top: 143px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;

  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}

#inp7 {
  box-sizing: border-box;
  padding-left: 12px;

  position: absolute;
  width: 149px;
  height: 38px;
  left: 1096px;
  top: 143px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #808080;

  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}

#inp6 {
  box-sizing: border-box;
  padding-left: 20px;

  position: absolute;
  width: 149px;
  height: 38px;
  left: 1573px;
  top: 143px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #808080;

  background: #242424;
  border: 1px solid #404040;
  border-radius: 4px;
  outline: none;
}

#pg1 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 758px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg2 {
  position: absolute;
  width: 24px;
  height: 25px;
  left: 796px;
  top: 1060px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #777777;
}

#pg3 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 835px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg4 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 901px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg5 {
  position: absolute;
  width: 12px;
  height: 25px;
  left: 976px;
  top: 1060px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;

  color: #FFFFFF;
}

#pg6 {
  position: absolute;
  width: 48px;
  height: 20px;
  left: 1015px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg7 {
  position: absolute;
  width: 32px;
  height: 20px;
  left: 1113px;
  top: 1062px;

  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

#pg8 {
  position: absolute;
  width: 16px;
  height: 20px;
  left: 1200px;
  top: 1062px;


  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
}

.Page {
  position: absolute;
  width: 60px;
  height: 16px;
  left: 1150px;
  top: 1062px;

  color: #4cc9f0;
  background: black;
  border-style: none;
}

#ip1 {
  font-family: 'Microsoft YaHei UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  outline: none;
}


/*表格通用样式*/
tr:nth-child(odd) {
  background-color: #000000;
}

tr:nth-child(even) {
  background-color: #101010;
}

th {
  background: #222222;
  height: 50px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #FFFFFF;
}

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #444444;
  height: 50px;

  font-family: 'Microsoft YaHei UI', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}


#timePeriod{
  position: absolute;
  width: 149px;
  height: 38px;
  left: 937px;
  top: 145px;
}
#dateTime{
  position: absolute;
  width: 149px;
  height: 38px;
  left: 707px;
  top: 145px;
}

#postName{
  position: absolute;
  width: 149px;
  height: 38px;
  left: 1255px;
  top: 145px;
}
</style>
